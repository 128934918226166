<template>
  <b-button-group>
    <!-- <b-button class="mr-1 d-none d-md-block" disabled> -->
    <!-- <b-button class="mr-2" disabled size="sm"> -->
      <!-- {{ indexSelected + 1 }} / {{ itemsLength}} -->
    <!-- </b-button> -->
    <b-button class="mr-1" @click="onPrev()">
      <b-icon icon="chevron-left"></b-icon>
    </b-button>
    <b-button class="mr-1" @click="onNext()">
      <b-icon icon="chevron-right"></b-icon>
    </b-button>

    <b-button @click="showTable()">
      <div class="d-none d-md-block"><b-icon icon="list-ul" class="mr-1"></b-icon>Lista</div>
      <div class="d-md-none"><b-icon icon="list-ul"></b-icon></div>
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  name: 'NavForm',
  props: {
    indexSelected: {
      required: true,
      type: Number
    },
    itemsLength: {
      required: true,
      type: Number
    }
  },
  methods: {
    onPrev() {
      this.$emit('record-prev')
    },
    onNext() {
      this.$emit('record-next')
    },
    showTable() {
      this.$emit('change-view')
    }
  }
}
</script>
