<template>
  <crud :service="service" :items="items" :fields="fields" :fileName="fileName" :isBusy="isBusy">

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:ItemFormLayout="{itemForm, isFormDisable}">

      <b-card bg-variant="light" >
        <!-- Fila 1 -->
        <!-- <h4>Detalles Del Chofer</h4> -->

        <b-form-row>

           <b-col sm="6">
            <b-form-group label-for="input-1" id="input-1"
            :invalid-feedback="invalidFeedbackImei(itemForm[names.IMEI])"
              :state="stateImei(itemForm[names.IMEI])">
              <label>{{ resourceDisplayName(names.IMEI) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-1" required type="number"
                v-model="itemForm[names.IMEI]" :state="stateImei(itemForm[names.IMEI])"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group label-for="input-15" id="input-15">
              <label>{{ resourceDisplayName(names.DATERELEASE) }}</label>
              <b-form-datepicker :disabled="isFormDisable" id="input-15"
                class="mb-2" placeholder="" hideHeader 
                today-button reset-button close-button
                label-today-button="Hoy" label-reset-button="Limpiar" label-close-button="Cerrar"
                labelHelp="Use el cursor para navegar"
                v-model="itemForm[names.DATERELEASE]"
              >
              </b-form-datepicker>
            </b-form-group>
           </b-col>
         </b-form-row>

        <!-- Fila 2 -->
        <b-form-row>
           <b-col sm="6">
            <b-form-group label-for="input-4" id="input-4"
              :invalid-feedback="invalidFeedbackNumber(itemForm[names.NUMBER])"
              :state="stateNumber(itemForm[names.NUMBER])">
              <label>{{ resourceDisplayName(names.NUMBER) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-4"  required trim type="number"
                v-model="itemForm[names.NUMBER]" :state="stateNumber(itemForm[names.NUMBER])"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group label-for="input-5" id="input-5">
              <label>{{ resourceDisplayName(names.PIN) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-5" trim type="number"
                v-model="itemForm[names.PIN]" 
              ></b-form-input>
            </b-form-group>
          </b-col>
          
          </b-form-row>

        <!-- Fila 3 -->
        <b-form-row>

           <b-col sm="6">
            <b-form-group label-for="input-7" id="input-7">
              <label>{{ resourceDisplayName(names.PUK) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-7" type="number"
                v-model="itemForm[names.PUK]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group label-for="input-8" id="input-8">
              <label>{{ resourceDisplayName(names.PAYMENT) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-8"
                v-model="itemForm[names.PAYMENT]"
              ></b-form-input>
            </b-form-group>
          </b-col>

         </b-form-row>

        <!-- Fila 4 -->
        <b-form-row>

          <b-col sm="6">
            <b-form-group label-for="input-12" id="input-12">
              <label>{{ resourceDisplayName(names.PROVIDER) }}</label>
              <select v-model="itemForm[names.PROVIDER]" :disabled="isFormDisable" class="form-control" >
                  <option v-for="providers in provider" v-bind:key="providers" :value="providers">
                    {{ providers }}
                  </option>
              </select>
             </b-form-group>
           </b-col>
      </b-form-row>
     </b-card>
 </template>
</crud>
</template>
<script>
import Crud from '@/components/Crud/Index';
import utils from '@/mixins/utils'
import resourcesChips from '@/mixins/resourcesChips'

export default {
	components: {
    Crud,
  },
  mixins: [resourcesChips],
  data () {
    return {
      titleModule: "Ajuste",
      titleSubModule: "Chips",
      isViewForm: false,
      indexSelected: -1,
      fields: resourcesChips.fields,
      names: resourcesChips.names,
      items: resourcesChips.items,

      provider: resourcesChips.provider,
      service: resourcesChips.service,
      isBusy: false,
      
    }
  },
  computed: {
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`
    },
    fileName() {
      return `${this.titleModule}_${this.titleSubModule}`
    }
  },
  methods: {
   stateImei(value) {
      if(!value) {
        return false
      } else {
        return value.length >= 15 ? true : false
      }
    },
    invalidFeedbackImei(value) {
      if(!value) {
        return 'Ingrese Imei'
      }
      if (value.length >= 15) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 15 caracteres'
      } else {
        return 'Ingrese Imei'
      }
    },
    stateNumber(value) {
      if(!value) {
        return false
      } else {
        return value.length >= 10 ? true : false
      }
    },
    invalidFeedbackNumber(value) {
      if(!value) {
        return 'Ingrese Número'
      }
      if (value.length >= 10) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 10 caracteres'
      } else {
        return 'Ingrese Numero'
      }
    },
  } 
}    
</script>
