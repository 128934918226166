<template>
  <crud :service="service" :items="items" :fields="fields" :fileName="fileName" :isBusy="isBusy" >

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:ItemFormLayout="{itemForm, isFormDisable}">

      <b-card bg-variant="light" >
        <!-- Fila 1 -->
        <b-form-row>
          <b-col sm="6">
            <b-form-group label-for="input-1" id="input-1"
                :invalid-feedback="invalidFeedbackItem(itemForm[names.ITEM_NAME])"
                :state="stateText(itemForm[names.ITEM_NAME])"
              >
              <label>{{ resourceDisplayName(names.ITEM_NAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-1" required type="text"
                v-model="itemForm[names.ITEM_NAME]" :state="stateText(itemForm[names.ITEM_NAME])"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label-for="input-2" id="input-2"
                :invalid-feedback="invalidFeedbackDescription(itemForm[names.ITEM_DESCRIPTION])"
                :state="stateText(itemForm[names.ITEM_DESCRIPTION])"
              >
              <label>{{ resourceDisplayName(names.ITEM_DESCRIPTION) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-2" required type="text"
                v-model="itemForm[names.ITEM_DESCRIPTION]" :state="stateText(itemForm[names.ITEM_DESCRIPTION])"
              ></b-form-input>
            </b-form-group>
          </b-col>
         </b-form-row>
         <!-- Fila 2 -->
        <b-form-row>
          <b-col sm="6">
            <b-form-group label-for="input-3" id="input-3"
                :invalid-feedback="invalidFeedbackNumber(itemForm[names.ITEM_KM])"
                :state="stateNumber(itemForm[names.ITEM_KM])"
              >
              <label>{{ resourceDisplayName(names.ITEM_KM) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-3" required trim type="number"
                v-model="itemForm[names.ITEM_KM]" :state="stateNumber(itemForm[names.ITEM_KM])"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label-for="input-4" id="input-4"
              :invalid-feedback="invalidFeedbackNumber(itemForm[names.ITEM_KM])"
              :state="stateNumber(itemForm[names.ITEM_KM])"
            >
              <label>{{ resourceDisplayName(names.ITEM_DAY) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-4" required trim type="number"
                v-model="itemForm[names.ITEM_DAY]" :state="stateNumber(itemForm[names.ITEM_DAY])"
              ></b-form-input>
            </b-form-group>
          </b-col>
         </b-form-row>
    </b-card>


    </template>

  </crud>
</template>

<script>
import Crud from '@/components/Crud/Index';
import resources from '@/mixins/resourcesItemsMaint'

export default {
  name: 'ConfigMantItems',
  mixins: [resources],
	components: {
    Crud
  },
  data () {
    return {
      titleModule: "Ajuste",
      titleSubModule: "Items",
      isViewForm: false,
      indexSelected: -1,
      fields: resources.fields,
      names: resources.names,
      items: resources.items,
      service: resources.service,
      isBusy: false,
    }
  },
  computed: {
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`
    },
    fileName() {
      return `${this.titleModule}_${this.titleSubModule}`
    }
  },
  methods: {
    stateText (value) {
      if(!value) {
        return false
      } else {
        return value.length >= 4 ? true : false
      }
    },
    invalidFeedbackItem(value) {
      if(!value) {
        return 'Ingrese Nombre del Item'
      }
      if (value.length >= 4) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 4 caracteres'
      } else {
        return 'Ingrese Nombre del Item'
      }
    },
    invalidFeedbackDescription(value) {
      if(!value) {
        return 'Ingrese una Descripción'
      }
      if (value.length >= 4) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 4 caracteres'
      } else {
        return 'Ingrese una Descripción'
      }
    },
    stateNumber (value) {
      if(!value) {
        return false
      } else {
        return value.length >= 1 ? true : false
      }
    },
    invalidFeedbackNumber(value) {
      if(!value) {
        return 'Ingrese un valor'
      }
      if (value.length >= 1) {
        return ''
      } else {
        return 'Ingrese un valor'
      }
    }

    // TODO control de ingresos y fechas de vencimiento
    // TODO datos a mostrar / editar
  }
}
</script>
