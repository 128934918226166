<template>
  <div>
    <b-button-toolbar v-if="isFormDisable" class="mb-3" justify key-nav>
      <!-- Button Group CRUD -->
      <b-button-group>
        <b-button @click="onCreate()" class="mr-1">
          <div class="d-none d-md-block"><b-icon icon="plus" class="mr-1"></b-icon>Nuevo</div>
          <div id="tooltip-item-create" class="d-md-none"><b-icon icon="plus"></b-icon>
            <b-tooltip target="tooltip-item-create" :variant="variantTooltip" :disabled.sync="isDisabledTooltip">
              Nuevo registro
            </b-tooltip>
          </div>

        </b-button>
        <b-button @click="onUpdate()" class="mr-1">
          <div class="d-none d-md-block"><b-icon icon="pencil" class="mr-1"></b-icon>Editar</div>
          <div id="tooltip-item-update" class="d-md-none"><b-icon icon="pencil"></b-icon>
            <b-tooltip target="tooltip-item-update" :variant="variantTooltip" :disabled.sync="isDisabledTooltip">
              Editar registro
            </b-tooltip>
          </div>

        </b-button>
        <b-button @click="onDelete()" variant="danger">
          <div class="d-none d-md-block"><b-icon icon="trash" class="mr-1"></b-icon>Eliminar</div>
          <div id="tooltip-item-delete" class="d-md-none"><b-icon icon="trash"></b-icon>
            <b-tooltip target="tooltip-item-delete" :variant="variantTooltip" :disabled.sync="isDisabledTooltip">
              Eliminar registro
            </b-tooltip>
          </div>

        </b-button>
      </b-button-group>

      <!-- Button Group EMPTY -->
      <b-button-group ></b-button-group >

      <!-- Button Group NAV -->
      <NavForm
        :indexSelected="indexSelected"
        :itemsLength="items.length"
        @change-view="$emit('change-view')"
        @record-prev="$emit('record-prev')"
        @record-next="$emit('record-next')"/>

    </b-button-toolbar>

    <!-- Button Group Form and Form Data -->
    <ItemFormDetail
      :itemForm="currentItemValue"
      :isFormDisable="isFormDisable"
      @submit="onSubmit"
      @reset="onReset">
          <template v-slot:ItemFormLayout__="{itemForm, isFormDisable}">
            <slot name="ItemFormLayout_"
                  v-bind:itemForm="itemForm"
                  v-bind:isFormDisable="isFormDisable"
            ></slot>
          </template>
    </ItemFormDetail>

    <!--
    <p> indexSelected:<br> {{ indexSelected }} </p>
    <p> Selected Rows:<br> {{ indexSelected>=0?items[indexSelected]:'[]' }} </p>
    <p> ItemCopy:<br> {{ this.itemCopy }} </p>
    -->

  </div>
</template>

<script>
  import ItemFormDetail from './ItemFormDetail'
  import NavForm from './NavForm'
  import utils from '@/mixins/utils'
  export default {
    name: 'ItemForm',
    mixins: [
      utils,
    ],
    components: {
      ItemFormDetail,
      NavForm
    },
    data() {
      return {
        isDisabledTooltip: false,
        variantTooltip: 'secondary',
        crudStatus: 'none',
        isFormDisable: true,
        currentItemValue: {},
      }
    },
    mounted() {
        this.currentItemValue = this.cloneObject(this.items[this.indexSelected]);
    },
    watch: {
      indexSelected: function (newIndexSelected, oldIndexSelected) {
        this.currentItemValue = this.cloneObject(this.items[this.indexSelected]);
      }
    },
    props: {
      items: {
        required: true,
        type: Array
      },
      indexSelected: {
        required: true,
        type: Number
      }
    },
    methods: {
      onCreate() {
        this.crudStatus = 'create'
        this.isFormDisable = false
        this.currentItemValue = this.cloneObjectStruct(this.items[this.indexSelected]);
      },
      onUpdate() {
        this.isFormDisable = false
        this.crudStatus = 'update'
      },
      onSubmit(item) {
        if ( this.crudStatus === 'update') {
          this.$emit('record-update', item, this.indexSelected)
        }
        if ( this.crudStatus === 'create') {
          this.$emit('record-create', item)
        }
        this.crudStatus = 'none'
        this.isFormDisable = true
      },
      onReset() {
        this.crudStatus = 'none'
        this.isFormDisable = true
        this.currentItemValue = this.cloneObject(this.items[this.indexSelected]);
      },
      onDelete() {
        // console.log('item >>>', this.items )
        // console.log('item idx >>>', this.indexSelected )
        this.$swal({
          // title: `Eliminar registo ${id} ?`,
          title: `Eliminar registo`,
          text: 'Cuidado, acción no reversible.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#f5365c',
          // cancelButtonColor: '#f7fafc',
          confirmButtonText: 'Si, eliminar !',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.value) {
            // this.$emit('record-delete', this.indexSelected)
            this.$emit('record-delete', this.items[this.indexSelected])
            this.$swal(
              'Borrado !',
              'El registro ha sido borrado.',
              'success'
            )
          }
          this.isFormDisable = true
          // this.crudStatus = 'none'
        })
      }
    }
  }
</script>
