<template>
  <crud :service="service" :items="items" :fields="fields" :fileName="fileName" :isBusy="isBusy">

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:ItemFormLayout="{itemForm, isFormDisable}">

      <b-card bg-variant="light" >
        <!-- Fila 1 -->
        <b-form-row>
          <b-col sm="4">
            <b-form-group label-for="input-1" id="input-1"
              :invalid-feedback="invalidFeedbackUsername(itemForm[names.USERNAME])"
              :state="stateUsername(itemForm[names.USERNAME])"
            >
              <label>{{ resourceDisplayName(names.USERNAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-1" required trim type="text"
                v-model="itemForm[names.USERNAME]" :state="stateUsername(itemForm[names.USERNAME])"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label-for="input-2" id="input-2"
              :invalid-feedback="invalidFeedbackName(itemForm[names.FIRSTNAME])"
              :state="stateName(itemForm[names.FIRSTNAME])"
            >
              <label>{{ resourceDisplayName(names.FIRSTNAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-2" required type="text"
                v-model="itemForm[names.FIRSTNAME]" :state="stateName(itemForm[names.FIRSTNAME])"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label-for="input-3" id="input-3"
              :invalid-feedback="invalidFeedbackName(itemForm[names.LASTNAME])"
              :state="stateName(itemForm[names.LASTNAME])"
            >
              <label>{{ resourceDisplayName(names.LASTNAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-3" required type="text"
                v-model="itemForm[names.LASTNAME]" :state="stateName(itemForm[names.LASTNAME])"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <!-- TODO seleccion de perfil y cliente para USUARIO-INGENEA
        -->   

        <!-- Fila 2 -->
        <!-- TODO validar de envio de email y activacion de usario con la respuesta
        -->
        <b-form-row>
          <b-col sm="4">
            <b-form-group label-for="input-4" id="input-4"
              :invalid-feedback="invalidFeedbackEmail(itemForm[names.EMAIL])"
              :state="stateEmail(itemForm[names.EMAIL])"
            >
              <label>{{ resourceDisplayName(names.EMAIL) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-4" required type="email"
                v-model="itemForm[names.EMAIL]" :state="stateEmail(itemForm[names.EMAIL])"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <template v-if="!isFormDisable">
              <b-form-group label-for="input-5" id="input-5"
                :invalid-feedback="invalidFeedbackEmailCheck(itemForm[names.EMAIL], itemForm.emailCheck)"
                :state="stateEmailCheck(itemForm[names.EMAIL], itemForm.emailCheck)"
              >
                <label> {{ resourceDisplayName(names.EMAIL) }} Control</label>
                <b-form-input :disabled="isFormDisable" id="input-5" trim required type="email"
                  v-model="itemForm.emailCheck" :state="stateEmailCheck(itemForm[names.EMAIL], itemForm.emailCheck)"
                ></b-form-input>
              </b-form-group>
            </template>
          </b-col>
        </b-form-row>

        <!-- Fila 3 -->
        <!-- TODO condicionar ingreso de password segun ADD/EDIT
          ADD sin boton de reset
          EDIT agregar boton para reset de password 
        -->
        <b-form-row>
          <b-col sm="4">
            <b-form-group label-for="input-6" id="input-6"
              :invalid-feedback="invalidFeedbackPass(itemForm[names.PASS])"
              :state="statePass(itemForm[names.PASS])"
            >
              <label> {{ resourceDisplayName(names.PASS) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-6" trim required type="password"
                v-model="itemForm[names.PASS]" :state="statePass(itemForm[names.PASS])"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <template v-if="!isFormDisable">
              <b-form-group label-for="input-7" id="input-7"
                :invalid-feedback="invalidFeedbackPassCheck(itemForm[names.PASS], itemForm.passCheck)"
                :state="statePassCheck(itemForm[names.PASS], itemForm.passCheck)"
              >
                <label> {{ resourceDisplayName(names.PASS) }} Control</label>
                <b-form-input :disabled="isFormDisable" id="input-7" trim required type="password"
                  v-model="itemForm.passCheck" :state="statePassCheck(itemForm[names.PASS], itemForm.passCheck)"
                ></b-form-input>
              </b-form-group>
            </template>
          </b-col>
        </b-form-row>

      </b-card>

    </template>

    <template v-slot:ItemTableLayout="{item}">
      <!-- TODO ajustar layout de campos de detalle de usuario 
        Perfil, Cliente, Estado (activo, baneado...), Estado_Razon
        Ultimo Login, Ultima IP???
       -->
        <b-card-group deck>
        <b-card :header=resourceDisplayName(names.CREATED) align="center">
          <b-card-text align="center"> {{ item[names.CREATED]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.MODIFIED) align="center">
          <b-card-text align="center"> {{ item[names.MODIFIED]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.CLIENT) align="center">
          <b-card-text align="center"> {{ item[names.CLIENT]}} </b-card-text>
        </b-card>      
      </b-card-group>
    </template>

  </crud>
</template>

<script>
import Crud from '@/components/Crud/Index';
import resources from '@/mixins/resourcesUser'
import validation from '@/mixins/validation'

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import userService from "../../../../ingeo-mf-styleguide/src/services/user";

/// #else
import { userService } from "@ingeo-mf/styleguide";

/// #endif

export default {
  name: 'ConfigUsuarios',
  mixins: [resources],
	components: {
    Crud
  },
  data () {
    return {
      titleModule: "Ajuste",
      titleSubModule: "Usuarios",
      isViewForm: false,
      indexSelected: -1,
      fields: resources.fields,
      names: resources.names,
      items: [],
      emailRule: validation.emailRule,
      passRules: validation.passRules,
      service: resources.service,
      isBusy: false,
    }
  },
   mounted() {
      userService.index().then((res) => {
      this.isBusy = true
        res.data.forEach(element => {
          this.items.push(element);
         });
         this.isBusy = false
         console.log("mounted ->  this.items",  this.items)
      });
  },
  computed: {
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`
    },
    fileName() {
      return `${this.titleModule}_${this.titleSubModule}`
    }
  },
  methods: {
    stateUsername (value) {
      if(!value) {
        return false
      } else {
        return value.length >= 4 ? true : false
      }
    },
    invalidFeedbackUsername(value) {
      if(!value) {
        return 'Ingrese usuario'
      }
      if (value.length >= 4) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 4 caracteres'
      } else {
        return 'Ingrese usuario'
      }
    },
    stateName (value) {
      if(!value) {
        return false
      } else {
        return value.length >= 4 ? true : false
      }
    },
    invalidFeedbackName(value) {
      if(!value) {
        return 'Ingrese usuario'
      }
      if (value.length >= 4) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 4 caracteres'
      } else {
        return 'Ingrese usuario'
      }
    },
    stateEmail (value) {
      if(!value) {
        return false
      } else {
        return this.emailRule.test(value)
      }
    },
    invalidFeedbackEmail(value) {
      if(!value) {
        return 'Ingrese Email'
      }
      return this.emailRule.test(value) ? '':'Ingrese un email valido'
    },
    stateEmailCheck (value, valueCheck) {
      if(!valueCheck) {
        return false
      } else {
        return value === valueCheck ? true : false
      }
    },
    invalidFeedbackEmailCheck(value, valueCheck) {
      if(!value) {
        return 'Ingrese Email'
      }
      if(!valueCheck) {
        return 'Repita el Email ingresado'
      }
      if (value === valueCheck ) {
        return ''
      } else {
        return 'Email no coincide'
      }
    },
    statePass (value) {
      if(!value) {
        return false
      } else {
        return this.passValidation(value).valid
      }
    },
    invalidFeedbackPass(value) {
      if(!value) {
        return 'Ingrese Password'
      }
      return this.passValidation(value).valid ? '':this.passValidation(value).errorMsj
    },
    passValidation(value) {
			let errorMsj = ''
			for (let condition of this.passRules) {
				if (!condition.regex.test(value)) {
					errorMsj += condition.message + ' '
				}
			}
			if (errorMsj.length === 0) {
				return { valid:true, errorMsj }
			} else {
				return { valid:false, errorMsj }
			}
    },
    statePassCheck (value, valueCheck) {
      if(!valueCheck) {
        return false
      } else {
        return value === valueCheck ? true : false
      }
    },
    invalidFeedbackPassCheck(value, valueCheck) {
      if(!value) {
        return 'Ingrese Password'
      }
      if(!valueCheck) {
        return 'Repita el password ingresado'
      }
      if (value == valueCheck) {
        return ''
      } else {
        return 'Password no coincide'
      }
    }
  }
}
</script>
