import Home from '@/views/Home/Index'

import Vehiculos from '@/views/Vehiculos/Index'
import Choferes from '@/views/Choferes/Index'

import Grupos from '@/views/Grupos/Index'
import GruposPivot from '@/views/GruposPivot/Index'

import MantPerfiles from '@/views/MantPerfiles/Index'
import MantItems from '@/views/MantItems/Index'

import Usuarios from '@/views/Usuarios/Index'
import Tracker from '@/views/Tracker/Index'
import Chips from '@/views/Chips/Index'
import Clientes from '@/views/Clientes/Index'

import Pivot from '@/views/Pivot/Index'

const routes = [
  {
    path: '/config',
    name: 'HomeConfig',
    component: Home,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/pivot',
    name: 'Pivot',
    component: Pivot,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/vehiculos',
    name: 'Vehiculos',
    component: Vehiculos,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/choferes',
    name: 'Choferes',
    component: Choferes,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/grupos',
    name: 'Grupos',
    component: Grupos,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/grupospivot',
    name: 'GruposPivot',
    component: GruposPivot,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/mantperfiles',
    name: 'Perfiles',
    component: MantPerfiles,
    meta: {
      public: false,          // Solo accede si está logueado
      onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/mantitems',
    name: 'Items',
    component: MantItems,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/chips',
    name: 'Chips',
    component: Chips,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/tracker',
    name: 'Tracker',
    component: Tracker,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/clientes',
    name: 'Clientes',
    component: Clientes,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/config/usuarios',
    name: 'Usuarios',
    component: Usuarios,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {path: '*', component: Home}   // cambiar a not found?
];

export default routes
