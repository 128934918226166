<template>
  <div id="config-content-wrapper">
    <!-- <div v-if="isBusy" class="d-flex justify-content-center text-success mt-5">
      <h4><strong class="mr-4">Cargando...</strong></h4>
      <b-spinner type="grow"></b-spinner>
    </div> -->

    <!-- <div v-else> -->
      <b-card>
        <div class="config-title">
          <div class="config-title-text">
            <div class="title"><h4> {{titleView}} </h4></div>
          </div>
          <template v-if="!isBusy">
            <div class="config-title-index"><h5>{{selected + 1}} / {{options.length}}</h5></div>
          </template>
        </div>     
  
        <template v-if="!isBusy">
          <b-button-toolbar class="mb-4" justify key-nav>
            <b-button-group>
              <b-form-select v-model="selected" :options="options"></b-form-select>
            </b-button-group>

            <b-button-group>
              <b-button class="mr-1" @click="onPrev()">
                <b-icon icon="chevron-left"></b-icon>
              </b-button>
              <b-button class="mr-1" @click="onNext()">
                <b-icon icon="chevron-right"></b-icon>
              </b-button>
            </b-button-group>
          </b-button-toolbar>

          <b-card-group deck>
            <b-card header="Grupo" align="center">
              <b-card-text align="center"> {{ groupSelected.text }} </b-card-text>
            </b-card>
            <b-card header=Descripción align="center">
              <b-card-text align="center"> {{ groupSelected.description }} </b-card-text>
            </b-card>
            <b-card header=Color align="center" >
              <b-card-text align="center" :style="groupColor"> &nbsp; </b-card-text>
            </b-card>
          </b-card-group>
        </template>

      </b-card>
      <br>
      <b-card>
        <div class="config-title">
          <template v-if="!isBusy">
            <div class="config-title-text">
              <h5>{{titlePivot[stepIndex]}} del grupo</h5>
            </div>
            <div class="config-title-index"><h5>{{stepIndex + 1}} / {{items.length}}</h5></div>
          </template>
          <template v-else> <div> &nbsp; </div> </template> 
        </div>

        <b-progress :value="stepValue" class="mb-3" height="2px" variant="danger"></b-progress>

        <b-button-toolbar class="mb-4" justify>
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text class="d-none d-md-block">
                <b-icon icon="search"></b-icon>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Filtrar. Ingrese texto..."
            ></b-input>
          </b-input-group>

          <b-button-group>
            <b-button class="mr-1" @click="onPrevStep()">
              <b-icon icon="chevron-left"></b-icon>
            </b-button>
            <b-button class="mr-1" @click="onNextStep()">
              <b-icon icon="chevron-right"></b-icon>
            </b-button>
          </b-button-group>
        </b-button-toolbar>

        <b-table
          striped
          hover
          stacked="sm"
          :items="items[stepIndex]"
          :fields="fields[stepIndex]"
          sort-icon-left
          :filter="filter"
          :tbody-tr-class="rowClass"
          :busy="isBusy"
        >

          <template #table-busy>
            <div class="text-center text-success my-2">
              <strong>Cargando...</strong>
              <b-spinner class="align-middle ml-3" type="grow"></b-spinner>
            </div>
          </template>

          <template v-slot:cell(check)="row">
            <b-icon 
              :icon="row.item.check?'check-square':'square'" 
              :variant="row.item.check?'primary':'danger'" 
              font-scale="1.2" 
              @click="toggleCheck(row.item, row.index)"
            ></b-icon>
          </template>
        </b-table>
      </b-card>
    <!-- </div> -->
  </div>
</template>

<script>
import resourcesGroup from '@/mixins/resourcesGroup'
import resourcesCars from '@/mixins/resourcesCars'
import resourcesUser from '@/mixins/resourcesUser'

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import crudService from "../../../../ingeo-mf-styleguide/src/services/crud";
import vehiculoService from "../../../../ingeo-mf-styleguide/src/services/vehiculo";
import userService from "../../../../ingeo-mf-styleguide/src/services/user";
import grupoService from "../../../../ingeo-mf-styleguide/src/services/grupo";
import grupoUsuarioService from "../../../../ingeo-mf-styleguide/src/services/grupo-usuario";
import grupoVehiculoService from "../../../../ingeo-mf-styleguide/src/services/grupo-vehiculo";
import choferService from "../../../../ingeo-mf-styleguide/src/services/chofer";

/// #else
import { crudService } from "@ingeo-mf/styleguide";
import { vehiculoService } from "@ingeo-mf/styleguide";
import { userService } from "@ingeo-mf/styleguide";
import { grupoService } from "@ingeo-mf/styleguide";
import { grupoUsuarioService } from "@ingeo-mf/styleguide";
import { grupoVehiculoService } from "@ingeo-mf/styleguide";
import { choferService } from "@ingeo-mf/styleguide";

/// #endif

export default {
  name: 'GruposPivot',
  mixins: [resourcesGroup, resourcesCars, resourcesUser],
  data() {
    return {
      isBusy: true,
      titleModule: resourcesGroup.titleModule,
      titleSubModule: resourcesGroup.titleSubModule,

      selected: null,
      options: [],
      filter: null,

      titlePivot: [],
      fields: [],
      items: [],
      stepIndex: 0,

      namesGroup: resourcesGroup.names,
      namesVehiculo: resourcesCars.names,
      namesUsuario: resourcesUser.names,

      serviceGroupVeh: resourcesCars.servicePivot,
      serviceGroupUsr: resourcesUser.servicePivot,

      itemsChecked: [], 

      checkVal: true,
      noCheckVal: false,
    }
  },
  mounted () {
    grupoService.index().then((res) => {
      this.isBusy = true
      let index = 0
      res.data.forEach(element => {
        this.options.push({ 
          value: index, 
          text: element[this.namesGroup.NAME],
          id: element[this.namesGroup.ID], 
          description: element[this.namesGroup.DESCRIPTION],
          color: element[this.namesGroup.COLOR]
        });
        index ++
      });

      let choferes = []
      choferService.index().then((res) => {
        res.data.forEach(element => {
          choferes.push(element);
        });

        vehiculoService.index().then((res) => {
          res.data.map(elem => {
            let idxChofer = choferes.findIndex(el => el[this.namesVehiculo.DRIVER_ID] === elem[this.namesVehiculo.DRIVER_ID])
            if (idxChofer !== -1) Object.assign(elem, choferes[idxChofer])
            elem.check = this.noCheckVal;
          });
          this.items.push(res.data);
          
          this.fields.push(resourcesCars.fieldsPivot); 
          this.titlePivot.push(resourcesCars.titleSubModule);

          userService.index().then((res) => {
            res.data.map(elem => {
              elem.check = this.noCheckVal;
              elem[this.namesUsuario.USERID] = elem[this.namesUsuario.ID];
            });
            this.items.push(res.data);
            
            this.fields.push(resourcesUser.fieldsPivot);
            this.titlePivot.push(resourcesUser.titleSubModule);

            grupoVehiculoService.index().then((res) => {
              this.itemsChecked.push(res.data);

              grupoUsuarioService.index().then((res) => {
                this.itemsChecked.push(res.data);
                this.isBusy = false
                this.selected = 0 // inicia seleccion y update
              });
            });
          });
        });
      });      
    });

  },
  computed: {
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`
    },
    stepValue() {
      let maxStep = this.items.length
      return 100 / maxStep * (this.stepIndex + 1) 
    },
    groupSelected() {
      if (this.options.length) {
        return this.options[this.selected]
      } else {
        return {
          value: '', 
          text: '',
          id: '', 
          description: '',
          color: ''
        }
      }
      this.updateCheck();
    },
    groupColor() {
      return `background-color: #${ this.groupSelected.color }; font-color: #${ this.groupSelected.color }; border-radius: 6px;`
    }
  },
  watch: {
    selected: function (newSelected, oldSelected) {
      this.updateCheck();
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.check === this.checkVal) return 'text-primary'
    },
    updateCheck() {
      let keys = [this.namesVehiculo.CAR_ID, this.namesUsuario.USERID];
      this.items.forEach((key, index) => {
        let subGroup = this.itemsChecked[index].filter(el => el.grupo_id === this.groupSelected.id);
        this.items[index].map(item => {
          item.check = subGroup.find(el => el[keys[index]] === item[keys[index]]) ? this.checkVal : this.noCheckVal
        });
      });
    },
    toggleCheck(item, index) {
      let keys = [this.namesVehiculo.CAR_ID, this.namesUsuario.USERID];
      let pivotKey = `${this.groupSelected.id}_${item[keys[this.stepIndex]]}`
      let servicePivot = [this.serviceGroupVeh, this.serviceGroupUsr]
      
      let dataVeh = {
        grupo_id: this.groupSelected.id,
        veh_id: item[keys[this.stepIndex]]
      }
      let dataUsr = {
        grupo_id: this.groupSelected.id,
        usr_id: item[keys[this.stepIndex]]
      }
      let data = this.stepIndex == 0 ? dataVeh:dataUsr
      
      item.check = !item.check

// console.log('item.check---> ', item.check)
// console.log('pivotKey ---> ', pivotKey)
// console.log('data ---> ', data)
// console.log('servicePivot[this.stepIndex].TABLE ---> ', servicePivot[this.stepIndex].TABLE)

      if(item.check) {
        // CREATE & Update itemsChecked
        crudService.create(servicePivot[this.stepIndex].TABLE, pivotKey, data)
          .then((res) => {
              console.log('create response >> ', res)
              this.itemsChecked[this.stepIndex].push(data)
            })
            .catch((error) => {
              console.log('create response error >> ', error)
              item.check = !item.check
              alert(error)
            });
      }
      else {
        // DELETE & Update itemsChecked
        crudService.delete(servicePivot[this.stepIndex].TABLE, pivotKey)
        .then((res) => {
          console.log('delete response >> ', res)
          let idx = this.itemsChecked[this.stepIndex].findIndex(el => el.grupo_id === this.groupSelected.id && el[keys[this.stepIndex]] === item[keys[this.stepIndex]])
// console.log('idx ---> ', idx)
          this.itemsChecked[this.stepIndex].splice(idx, 1);
        })
        .catch((error) => {
          console.log('delete response error >> ', error)
          item.check = !item.check
          alert(error)
        });
      }
// console.log('this.itemsChecked[this.stepIndex] POST ---> ', this.itemsChecked[this.stepIndex])

    },
    onPrev() {
      // TODO control de items lenght CERO
      if(this.selected > 0) {
        this.selected --
      } else {
        this.selected = this.options.length - 1
      }
    },
    onNext() {
      // TODO control de items lenght CERO
      if(this.selected < this.options.length - 1) {
        this.selected ++
      } else {
        this.selected = 0
      }
    },
    onPrevStep() {
      if(this.stepIndex > 0) {
        this.stepIndex --
      } else {
        this.stepIndex = this.items.length - 1
      }
    },
    onNextStep() {
      if(this.stepIndex < this.items.length - 1) {
        this.stepIndex ++
      } else {
        this.stepIndex = 0
      }
    },
  }
}
</script>