const service = {
  TABLE: 'chips',
  PRIMARY_KEY: 'chip_imei'
};
const names = {
  ID: 'chip_id',
  IMEI: 'chip_imei',
  DATERELEASE: 'chip_fecha_alta',
  STATE: 'chip_estado',
  NUMBER: 'chip_numero',
  PIN: 'chip_pin1',
  PUK: 'chip_puk',
  PAYMENT: 'chip_abono_id',
  PROVIDER: 'chip_proveedor',
}

const resourceDisplayName = {
  [names.ID]: 'Id',
  [names.IMEI]: 'Imei',
  [names.DATERELEASE]: 'Fecha Alta',
  [names.STATE]: 'Estado',
  [names.NUMBER]: 'Número',
  [names.PIN]: 'Pin',
  [names.PUK]: 'Puk',
  [names.PAYMENT]: 'Abono',
  [names.PROVIDER]: 'Proveedor',
  
}

const fields = [
  // {
  //   key: 'index',
  //   label: '#'
  // },
  {
    key: names.IMEI,
    label: resourceDisplayName[names.IMEI],
    sortable: true
  },
  {
    key: names.DATERELEASE,
    label: resourceDisplayName[names.DATERELEASE],
    sortable: true
  },
  {
    key: names.NUMBER,
    label: resourceDisplayName[names.NUMBER],
    sortable: true
  },
  {
    key: names.PIN,
    label: resourceDisplayName[names.PIN],
    sortable: true
  },
  {
    key: names.PUK,
    label: resourceDisplayName[names.PUK],
    sortable: true
  },
  {
    key: names.PAYMENT,
    label: resourceDisplayName[names.PAYMENT],
    sortable: true
  },
  {
    key: names.PROVIDER,
    label: resourceDisplayName[names.PROVIDER],
    sortable: true
  }
]

const items = [
  {chip_imei:"079900001420238", chip_fecha_alta:"2017-02-09", chip_numero:"1128402022", chip_pin1:"1234", chip_puk:"48706742", chip_abono_id:"3", chip_proveedor:"Movistar"},
  {chip_imei:"407990000142024", chip_fecha_alta:"2017-02-09", chip_numero:"1128402023", chip_pin1:"1234", chip_puk:"15830347", chip_abono_id:"3", chip_proveedor:"Movistar"},
  {chip_imei:"079900001424636", chip_fecha_alta:"2017-02-09", chip_numero:"1128402024", chip_pin1:"1234", chip_puk:"35063023", chip_abono_id:"3", chip_proveedor:"Movistar"},
  {chip_imei:"079900001424644", chip_fecha_alta:"2017-02-22", chip_numero:"1128402463", chip_pin1:"1234", chip_puk:"81483136", chip_abono_id:"3", chip_proveedor:"Movistar"}
]

const provider = ['Movistar','Claro', 'Personal', 'Tuenti']

export default {
  items,
  provider,
  names,
  fields,
  service,
  methods: {
    /**
     * Resource Normalized name
     * @param regKey {String}
     * @returns {String}
     */
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    }
  }
}
