<template>
  <crud :service="service" :items="items" :fields="fields" :fileName="fileName" :isBusy="isBusy">

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:ItemFormLayout="{itemForm, isFormDisable}">

      <b-card bg-variant="light" >
        <!-- Fila 1 -->
        <b-form-row>
          <b-col sm="6">
            <b-form-group label-for="input-1" id="input-1"
                :invalid-feedback="invalidFeedbackName(itemForm[names.CLI_NAME])"
                :state="stateText(itemForm[names.CLI_NAME])"
              >
              <label>{{ resourceDisplayName(names.CLI_NAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-1" required type="text"
                v-model="itemForm[names.CLI_NAME]" :state="stateText(itemForm[names.CLI_NAME])"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label-for="input-2" id="input-2"
                :invalid-feedback="invalidFeedbackNumber(itemForm[names.CLI_ACCOUNT])"
                :state="stateNumber(itemForm[names.CLI_ACCOUNT])"
              >
              <label>{{ resourceDisplayName(names.CLI_ACCOUNT) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-2" required type="number"
                v-model="itemForm[names.CLI_ACCOUNT]" :state="stateNumber(itemForm[names.CLI_ACCOUNT])"
              ></b-form-input>
            </b-form-group>
          </b-col>
         </b-form-row>
         <!-- Fila 2 -->
        <b-form-row>
          <b-col sm="6" v-if="isFormDisable" >
            <b-form-group label-for="input-5" id="input-5" >
                <label> {{ resourceDisplayName(names.CLI_LOGO) }} </label>
              <b-card img-left align="left" style=" max-height: 60px;">
                 <img 
                  :src= "`/img/${itemForm.cli_logo_path}`"
                  height="40"
                  wigth="40"
                  />
                </b-card>
                
             </b-form-group>
          </b-col>
          <!-- <b-col sm="6" v-if="!isFormDisable">
            <b-form-group label-for="input-5">
                <label>{{ resourceDisplayName(names.CLI_LOGO) }}</label>
               <b-form-file :disabled="isFormDisable" 
                              v-model="file1"
                              @change="updateProfilePic"
                              id="input-5"></b-form-file>
            </b-form-group>
          </b-col> -->
         <b-col sm="6" >
            <b-form-group label-for="input-4" id="input-4"
              :invalid-feedback="invalidFeedbackNumber(itemForm[names.CLI_BALANCE])"
              :state="stateNumber(itemForm[names.CLI_BALANCE])" 
            >
              <label>{{ resourceDisplayName(names.CLI_BALANCE) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-4" required trim type="number"
                v-model="itemForm[names.CLI_BALANCE]" :state="stateNumber(itemForm[names.CLI_BALANCE])"
              ></b-form-input>
            </b-form-group>
          </b-col>
         </b-form-row>
          <!-- Fila 3 -->
        <!-- <b-form-row> -->
           
          <!-- <b-col sm="6">
            <b-form-group label-for="input-3" id="input-3">
              <label>{{ resourceDisplayName(names.CLI_EMP_ID) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-3" required trim 
                v-model="itemForm[names.CLI_EMP_ID]"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label-for="input-4" id="input-4">
              <label>{{ resourceDisplayName(names.CLI_VEND_ID) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-4" required trim type=""
                v-model="itemForm[names.CLI_VEND_ID]"
              ></b-form-input>
            </b-form-group>
          </b-col> -->
         <!-- </b-form-row> -->
    </b-card>


    </template>

  </crud>
</template>

<script>
import Crud from '@/components/Crud/Index';
import resourcesClient from '@/mixins/resourcesClients'

import ItemFormDetailVue from '../../components/Crud/ItemForm/ItemFormDetail.vue';

export default {
  name: 'ConfigClients',
  mixins: [resourcesClient],
	components: {
    Crud
  },
  data () {
    return {
      titleModule: "Ajuste",
      titleSubModule: "Clientes",
      isViewForm: false,
      indexSelected: -1,
      fields: resourcesClient.fields,
      names: resourcesClient.names,
      items: resourcesClient.items,
      file1: null,
      isBusy: false,
      service: resourcesClient.service,
    }
  },
 
 computed: {
    
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`

    },
    fileName() {
      return `${this.titleModule}_${this.titleSubModule}`
    }
  },
  methods: {
   updateProfilePic(element){
    this.file1 = element.target.files[0];
            console.log("this.file1",this.file1);
            let reader = new FileReader();
            reader.onloadend = function(element) {
              this.file1 = element.target.result;
              console.log("reader.onloadend -> file",  this.file1)
                // console.log('RESULT', reader.result)
            }
            
            reader.readAsDataURL(this.file1);
            
        },
    
    stateText (value) {
      if(!value) {
        return false
      } else {
        return value.length >= 1 ? true : false
      }
    },
    invalidFeedbackName(value) {
      if(!value) {
        return 'Ingrese Nombre del Cliente'
      }
      if (value.length >= 1) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 1 caracter'
      } else {
        return 'Ingrese Nombre del Cliente'
      }
    },

    
    stateNumber (value) {
      if(!value) {
        return false
      } else {
        return value.length >= 1 ? true : false
      }
    },
    invalidFeedbackNumber(value) {
      if(!value) {
        return 'Ingrese un valor'
      }
      if (value.length >= 1) {
        return ''
      } else {
        return 'Ingrese un valor'
      }
    }

    // TODO control de ingresos y fechas de vencimiento
    // TODO datos a mostrar / editar
  }
}
</script>
