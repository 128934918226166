<template>
  <b-card >
    <slot name="ItemTableLayout__"
          v-bind:item="item">
    </slot>
    <a @click="toggleDetails" class="mh-1 mx-5">
      <b-icon icon="caret-up-fill"></b-icon>
    </a> 
  </b-card>
</template>

<script>
  export default {
    name: 'ItemTableDetail',
    props: {
      item: {
        required: true,
        type: Object
      }
    },
    methods: {
      toggleDetails() {
        this.$emit('toggle-details')
      }
    }
  }
</script>
