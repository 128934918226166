export default {
  methods: {
    cloneObject(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    cloneObjectStruct(obj) {
      if (null == obj || "object" != typeof obj) return obj;
      var copy = obj.constructor();
      for (var attr in obj) {
          if (obj.hasOwnProperty(attr)) copy[attr] = null;
      }
      return copy;
    }
  }
};
