<template>
  <b-form @submit="onSubmit" @reset="onReset">

    <!-- Submit and Cancel Buttons -->
    <b-button-toolbar v-if="!isFormDisable" class="mb-4" justify key-nav>
      <b-button-group ></b-button-group >
      <b-button-group >
        <b-button variant="success" type="submit" class="mr-4">
          <div class="d-none d-sm-block"><b-icon icon="check" class="mr-1"></b-icon>Guardar</div>
          <div id="tooltip-item-save" class="d-sm-none"><b-icon icon="check"></b-icon></div>
          <b-tooltip target="tooltip-item-save" :variant="variantTooltip" :disabled.sync="isDisabledTooltip">
            Guardar registro
          </b-tooltip>
        </b-button>
        <b-button variant="danger" type="reset" class="ml-4">
          <div class="d-none d-sm-block"><b-icon icon="x" class="mr-1"></b-icon>Cancelar</div>
          <div id="tooltip-item-cancel" class="d-sm-none"><b-icon icon="x"></b-icon></div>
          <b-tooltip target="tooltip-item-cancel" :variant="variantTooltip" :disabled.sync="isDisabledTooltip">
            Cancelar
          </b-tooltip>
        </b-button>
      </b-button-group>
      <b-button-group ></b-button-group >
    </b-button-toolbar>

    <slot name="ItemFormLayout__"
          v-bind:itemForm="itemForm"
          v-bind:isFormDisable="isFormDisable"
      ></slot>

  </b-form>
</template>

<script>
export default {
  name: 'ItemForm',
  data() {
    return {
      isDisabledTooltip: false,
      variantTooltip: 'secondary',
    }
  },
  props: {
    itemForm: {
      required: true,
      type: Object
    },
    isFormDisable: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.$emit('submit', this.itemForm)
    },
    onReset(evt) {
      evt.preventDefault()
      this.$emit('reset')
    }
  }
}
</script>
