<template>
  <div id="content-wrapper-mf-config">
    <b-button-toolbar class="mb-3" justify aria-label="Toolbar with button groups and input groups">
      <!-- <b-button-group> -->
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text class="d-none d-md-block">
              <b-icon icon="search"></b-icon>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Ingrese texto..."
          ></b-input>
        </b-input-group>
      <!-- </b-button-group> -->

      <b-button-group>
        <b-button class="ml-1">
          <download-excel
            type = "xls"
            worksheet = "Vehiculos"
            :name = "fullFileName"
            :data = "items">
            <div class="d-none d-md-block"><b-icon icon="download" class="mr-1"></b-icon>Export</div>
            <div class="d-md-none"><b-icon icon="download"></b-icon></div>
          </download-excel>
        </b-button>
        <b-button class="ml-1" @click="showForm()">
          <div class="d-none d-md-block"><b-icon icon="grid-fill" class="mr-1"></b-icon>Registros</div>
          <div class="d-md-none"><b-icon icon="grid-fill"></b-icon></div>
        </b-button>
      </b-button-group>
    </b-button-toolbar>

    <b-table
      striped
      hover
      stacked="sm"
      :small="isTableSmall"
      :dark="isTableDark"
      :head-variant="headVariant"
      :items="items"
      :fields="fields"
      :borderless="borderless"
      :busy="isBusy"
      ref="selectableTable"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
      sort-icon-left
      :filter="filter"
      empty-text="No hay registros"
      empty-filtered-text="Filtro sin coincidencias"
      show-empty
    >

      <template #table-busy>
        <div class="text-center text-success my-2">
          <strong>Cargando...</strong>
          <b-spinner class="align-middle ml-3" type="grow"></b-spinner>
        </div>
      </template>

      <!-- A virtual column -->
        <!-- <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template> -->
      <!-- End virtual column -->

      <!-- Cell Customization -->
        <!-- <template v-slot:cell(group_color)="{value}">
            <div :style="{ 'background-color': value, 'font-color': value}">
              &nbsp;
            </div>
        </template>  -->
      <!-- <template v-slot:[getCell(key)]="data" v-for="key in customKeys">
        <span v-html="data.value" v-bind:key="key"> </span>
      </template> -->

      <template v-for="item in fields" v-slot:[`cell(${item.key})`]="data">
        <template v-if="item.customCell">
          <span v-html="data.value" v-bind:key="item.key"> </span>
        </template>
        <template v-else>
          {{data.value}}
        </template>
      </template>
      <!-- End cell custom  -->

      <!-- Detalle de Filas -->
      <template v-slot:cell(show_details)="row">
        <a @click="row.toggleDetails" class="px-4">
          <template v-if="row.detailsShowing">
            <b-icon icon="caret-up-fill"></b-icon>
          </template>
          <template v-else>
            <b-icon icon="caret-down-fill"></b-icon>
          </template>
        </a>
      </template>

      <template v-slot:row-details="row">
        <ItemTableDetail
          :item="row.item"
          @toggle-details="row.toggleDetails">
            <template v-slot:ItemTableLayout__="{item}">
              <slot name="ItemTableLayout_"
                v-bind:item="item"
              ></slot>
            </template>
          </ItemTableDetail>
      </template>
      <!-- Fin Detalle de Filas -->
    </b-table>

<!-- <br>
<br>
<br>

    <b-form-group label="Table Options" label-cols-lg="2">
      <b-form-checkbox v-model="isTableSmall" inline>Small</b-form-checkbox>
      <b-form-checkbox v-model="isTableDark" inline>Dark</b-form-checkbox>
    </b-form-group>

    <b-form-group label="Head Variant" label-cols-lg="2">
      <b-form-radio-group v-model="headVariant" class="mt-lg-2">
        <b-form-radio value="light" inline>Light</b-form-radio>
        <b-form-radio value="dark" inline>Dark</b-form-radio>
        <b-form-radio :value="null" inline>None</b-form-radio>
      </b-form-radio-group>
    </b-form-group> -->

  </div>
</template>

<script>
  import ItemTableDetail from './ItemTableDetail'
  import utils from '@/mixins/utils'
  import customCell from '@/mixins/customCell'

  export default {
    name: 'ItemTable',
    components: {
      ItemTableDetail
    },
    mixins: [
      utils,
      customCell
    ],
    data() {
      return {
        isTableSmall: false,
        isTableDark: false,
        headVariant: 'light',
        borderless: false,
        selected: [],
        filter: null
      }
    },
    methods: {
      showForm() {
        this.$emit('change-view')
      },
      onRowSelected(item) {
        let index = -1
        if (item.length) {
          index = this.items.findIndex(i => i.veh_id === item[0].veh_id)
        }
        this.$emit('selected', index)
        this.selected = item
      }
    },
    computed: {
      fullFileName () {
        let now = new Date();
        let nowDay = now.toISOString().slice(0,10).replace(/-/g,"");
        return `${this.fileName.trim().toLowerCase()}_${nowDay}.xls`
      }
    },
    props: {
      fileName: {
        required: true,
        type: String
      },
      fields: {
        required: true,
        type: Array
      },
      items: {
        required: true,
        type: Array
      },
      isBusy: {
        required: true,
        type: Boolean
      },
      indexSelected: {
        required: true,
        type: Number
      }
    }
  }
</script>
<style lang="css">
  #content-wrapper-mf-config {
    height: calc(100vh - 70px);
    margin-top: 65px;
  }
</style>
