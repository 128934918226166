<template>
  <crud :service="service" :items="items" :fields="fields" :fileName="fileName" :isBusy="isBusy">

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:ItemFormLayout="{itemForm, isFormDisable}">

      <b-card bg-variant="light" >
        <!-- Fila 1 -->
        <b-form-row>
          <b-col sm="6">
            <b-form-group label-for="input-1" id="input-1"
                :invalid-feedback="invalidFeedbackTracker(itemForm[tracker.ID])"
                :state="stateTracker(itemForm[tracker.ID])">
              <label>{{ resourceDisplayName(tracker.ID) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-1" required trim type="text"
                v-model="itemForm[tracker.ID]" :state="stateTracker(itemForm[tracker.ID])">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label-for="input-2" id="input-2">
              <label>{{ resourceDisplayName(tracker.TRACKER_HW) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-2" required type="text"
                v-model="itemForm[tracker.TRACKER_HW]"
              ></b-form-input>
            </b-form-group>
          </b-col>
         </b-form-row>

         <!-- Fila 2 -->
         <b-form-row>
          <b-col sm="6">
            <b-form-group label-for="input-3" id="input-3">
              <label>{{ resourceDisplayName(tracker.TRACKER_SERIE) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-3" required type="text"
                v-model="itemForm[tracker.TRACKER_SERIE]">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label-for="input-9">
              <label>{{ resourceDisplayName(tracker.STATE) }}</label>
              <b-form-select  :disabled="isFormDisable" id="input-9"  v-model="itemForm[tracker.STATE]" :options="estado" required trim ></b-form-select>
            </b-form-group>
          </b-col>
          <!-- <b-col sm="6">
            <b-form-group label-for="input-4" id="input-4">
              <label>{{ resourceDisplayName(tracker.STATE) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-4" required type="text"
                v-model="itemForm[tracker.STATE]"
              ></b-form-input>
            </b-form-group>
          </b-col>  -->
           <b-col sm="6">
            <b-form-group label-for="input-4" id="input-5">
              <label>{{ resourceDisplayName(tracker.TRACKER_CHIP_NUMBER1) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-5" required type="text"
                v-model="itemForm[tracker.TRACKER_CHIP_NUMBER1]"
              ></b-form-input>
            </b-form-group>
          </b-col> 
          <b-col sm="6">
            <b-form-group label-for="input-4" id="input-6">
              <label>{{ resourceDisplayName(tracker.TRACKER_CHIP_NUMBER2) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-6" required type="text"
                v-model="itemForm[tracker.TRACKER_CHIP_NUMBER2]"
              ></b-form-input>
            </b-form-group>
          </b-col> 
           
          <!-- <b-col sm="6">
            <b-form-group label-for="input-4" id="input-7">
              <label>{{ resourceDisplayName(tracker.TRACKER_CLIENT) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-7" required type="text"
                v-model="itemForm[tracker.TRACKER_CLIENT]"
              ></b-form-input>
            </b-form-group>
          </b-col>  -->
           <b-col sm="6">
            <b-form-group label-for="input-6">
              <label>{{ resourceDisplayName(tracker.TRACKER_CLIENT) }}</label>
              <b-form-select  :disabled="isFormDisable" id="input-6"  v-model="itemForm[tracker.TRACKER_CLIENT]" :options="cliente" required trim ></b-form-select>
            </b-form-group>
          </b-col>
          
           <b-col sm="6">
            <b-form-group label-for="input-4" id="input-8">
              <label>{{ resourceDisplayName(tracker.TRACKER_TARGET_PORT) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-8" required type="text"
                v-model="itemForm[tracker.TRACKER_TARGET_PORT]"
              ></b-form-input>
            </b-form-group>
          </b-col> 
          <!-- <b-col sm="6">
            <b-form-group label-for="input-4" id="input-4">
              <label>{{ resourceDisplayName(tracker.TRACKER_CAR) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-4" required type="text"
                v-model="itemForm[tracker.TRACKER_CAR]"
              ></b-form-input>
            </b-form-group>
          </b-col> -->
         </b-form-row>
    </b-card>


    </template>
     <template v-slot:ItemTableLayout="{item}">
      <b-card-group deck>
        <b-card :header=resourceDisplayName(tracker.TRACKER_CLIENT) align="center">
          <b-card-text align="center"> {{ item[tracker.TRACKER_CLIENT]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(tracker.TRACKER_NAME_CLIENT) align="center">
          <b-card-text align="center"> {{ item[tracker.TRACKER_NAME_CLIENT]}} </b-card-text>
        </b-card>
         <b-card :header=resourceDisplayName(tracker.TRACKER_TARGET_PORT) align="center">
          <b-card-text align="center"> {{ item[tracker.TRACKER_TARGET_PORT]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(tracker.TRACKER_OBSERVATIONS) align="center">
          <b-card-text align="center"> {{ item[tracker.TRACKER_OBSERVATIONS]}} </b-card-text>
        </b-card>
      </b-card-group>

    </template>

  </crud>
</template>

<script>
import Crud from '@/components/Crud/Index';
import resources from '@/mixins/resourcesTracker'
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import trackerService from "../../../../ingeo-mf-styleguide/src/services/tracker";
/// #else
import { trackerService } from "@ingeo-mf/styleguide";
/// #endif

export default {
  name: 'ConfigTracker',
  mixins: [resources],
	components: {
    Crud
  },
  data () {
    return {
     titleModule: resources.titleModule,
      titleSubModule: resources.titleSubModule,
      isViewForm: false,
      indexSelected: -1,
      fields: resources.fields,
      tracker: resources.tracker,
      items: [],
      service: resources.service,
      estado: resources.estado,
      cliente: resources.cliente,
      isBusy: false,
    }
  },
  mounted () {
    let client = []

     this.cliente.forEach(element => 
        client.push(element));

      trackerService.index().then((res) => {
         this.isBusy = true
        res.data.forEach(elem => {
          let idxCliente = client.findIndex(el => el.value === elem[this.tracker.TRACKER_CLIENT].toString() )
          if (idxCliente !== -1) Object.assign(elem, client[idxCliente])
        });
        // console.log(res.data)
        res.data.forEach(element => {
            this.items.push(element);
         });
        //  this.items.push(res.data);
         this.isBusy = false
      });

     
      console.log("trackers",this.items);
  },
  computed: {
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`
    },
    fileName() {
      return `${this.titleModule}_${this.titleSubModule}`
    }
  },
  methods: {
    stateTracker (value) {
      if(!value) {
        return false
      } else {
        return value.length >= 3 ? true : false
      }
    },
    invalidFeedbackTracker(value) {
      if(!value) {
        return 'Ingrese Tracker'
      }
      if (value.length >= 3) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 3 caracteres'
      } else {
        return 'Ingrese Tracker'
      }
    }
    // TODO control de ingresos
    // TODO datos a mostrar / editar
  }
}
</script>
