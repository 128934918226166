const service = {
  TABLE: 'clientes',
  PRIMARY_KEY: 'cli_id'
};

const items =
[
  {
    cli_id: '1',
    cli_nombre: 'Ingenea',
    cli_cuenta: '1',
    cli_estado: '2',
    vend_id: '1',
    cli_saldo: '10.00',
    emp_id: '1',
    cli_logo_path: 'ingeoicon.png'
    // @/assets/img/ingeoicon.png
    // "images/logos/logo_ingeo.png"
  },
  {
    cli_id: '2',
    cli_nombre: 'CSDNET',
    cli_cuenta: '1',
    cli_estado: '0',
    vend_id: '1',
    cli_saldo: '0.00',
    emp_id: '2',
    cli_logo_path: 'Comercial.jpg'
    // "cli_logo_path": "images/logos/logo_csdnet.png"
  },
  {
    cli_id: '3',
    cli_nombre: 'GCBA',
    cli_cuenta: '1',
    cli_estado: '0',
    vend_id: '1',
    cli_saldo: '0.00',
    emp_id: '5',
    cli_logo_path: 'ingeoicon.png'
    // "cli_logo_path": "images/logos/logogcba.png"
  },
  {
    cli_id: '4',
    cli_nombre: 'SOLFLIX',
    cli_cuenta: '2',
    cli_estado: '0',
    vend_id: '1',
    cli_saldo: '456.00',
    emp_id: '17',
    cli_logo_path: 'ingeoicon.png'
    // "cli_logo_path": "images/logos/logo_igwla.png"
  },
  {
    cli_id: '5',
    cli_nombre: 'Rossini',
    cli_cuenta: '1',
    cli_estado: '0',
    vend_id: '1',
    cli_saldo: '0.00',
    emp_id: '20',
    cli_logo_path: 'ingeoicon.png'
    // "cli_logo_path": "images/logos/logo_rossini.png"
  },
]

const names = {
  ID: 'cli_id',
  CLI_ACCOUNT: 'cli_cuenta',
  CLI_STATE: 'cli_estado',
  CLI_LOGO: 'cli_logo_path',
  CLI_NAME: 'cli_nombre',
  CLI_BALANCE: 'cli_saldo',
  CLI_EMP_ID: 'emp_id',
  CLI_VEND_ID: 'vend_id',
  
}

const resourceDisplayName = {
  [names.ID]: 'Id',
  [names.CLI_ACCOUNT]: 'Cuenta',
  [names.CLI_STATE]: 'Estado',
  [names.CLI_LOGO]: 'Logo',
  [names.CLI_NAME]: 'Nombre',
  [names.CLI_BALANCE]: 'Saldo',
  [names.CLI_EMP_ID]: 'Emp',
  [names.CLI_VEND_ID]: 'Vend',
}

const fields = [
  // {
  //   key: 'index',
  //   label: '#'
  // },
  {
    key: names.CLI_NAME,
    label: resourceDisplayName[names.CLI_NAME],
    sortable: true
  },
  {
    key: names.CLI_ACCOUNT,
    label: resourceDisplayName[names.CLI_ACCOUNT],
    sortable: true
  },
  {
    key: names.CLI_BALANCE,
    label: resourceDisplayName[names.CLI_BALANCE],
    sortable: true
  },
  // {
  //   key: names.CLI_STATE,
  //   label: resourceDisplayName[names.CLI_STATE],
  //   sortable: true
  // },
  {
    key: names.CLI_EMP_ID,
    label: resourceDisplayName[names.CLI_EMP_ID],
    sortable: true
  },
  {
    key: names.CLI_VEND_ID,
    label: resourceDisplayName[names.CLI_VEND_ID],
    sortable: true
  },
  {
    key: names.CLI_LOGO,
    label: resourceDisplayName[names.CLI_LOGO],
    sortable: true,
    customCell: true,
    formatter(cellValue, options, rowObject) { 
      let cell = "<img width='30px' src=" + require(`@/assets/img/icons/${cellValue}`) +"/>"  
    return cell;
    }
  },
  
];

export default {
  names,
  fields,
  service,
  items,
  methods: {
    /**
     * Resource Normalized name
     * @param regKey {String}
     * @returns {String}
     */
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    }
  }
}
