const service = {
  TABLE: 'mantItems',
  PRIMARY_KEY: 'mant_item_id'
};
const names = {
  ID: 'id',
  ITEM_NAME: 'item_name',
  ITEM_DESCRIPTION: 'item_description',
  ITEM_KM: 'item_km',
  ITEM_DAY: 'item_day'
}

const resourceDisplayName = {
  [names.ID]: 'Id',
  [names.ITEM_NAME]: 'Nombre',
  [names.ITEM_DESCRIPTION]: 'Descripción',
  [names.ITEM_KM]: 'Km',
  [names.ITEM_DAY]: 'Día',
  
}

const fields = [
  // {
  //   key: 'index',
  //   label: '#'
  // },
  {
    key: names.ITEM_NAME,
    label: resourceDisplayName[names.ITEM_NAME],
    sortable: true
  },
  {
    key: names.ITEM_DESCRIPTION,
    label: resourceDisplayName[names.ITEM_DESCRIPTION],
    sortable: true
  },
  {
    key: names.ITEM_KM,
    label: resourceDisplayName[names.ITEM_KM],
    sortable: true
  },
  {
    key: names.ITEM_DAY,
    label: resourceDisplayName[names.ITEM_DAY],
    sortable: true
  },
  
];

const items =
[
  {
    "id": "1",
    "item_name": "Service",
    "item_description": "Service en Aupesa",
    "item_km": "10000",
    "item_day": "365",
  },
  {
    "id": "2",
    "item_name": "Rotación de cubiertas",
    "item_description": "Rotación de cubiertas",
    "item_km": "10000",
    "item_day": "0",
  },
  {
    "id": "3",
    "item_name": "Control General",
    "item_description": "Matafuegos, otros",
    "item_km": "0",
    "item_day": "365",
  },
  {
    "id": "4",
    "item_name": "Cambio de aceite",
    "item_description": "Cambio de aceite",
    "item_km": "7500",
    "item_day": "180",
  },
  {
    "id": "5",
    "item_name": "Cambio de filtro combustible",
    "item_description": "Filtros de combustible",
    "item_km": "14000",
    "item_day": "360",
  },
  {
    "id": "6",
    "item_name": "Pastillas de Freno",
    "item_description": "Cambio de Pastillas de Freno",
    "item_km": "120000",
    "item_day": "720",
  },
  
];

export default {
  items,
  names,
  fields,
  service,
  methods: {
    /**
     * Resource Normalized name
     * @param regKey {String}
     * @returns {String}
     */
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    }
  }
}
