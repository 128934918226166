const service = {
  TABLE: 'vehiculo',
  PRIMARY_KEY: 'veh_id'
};

const servicePivot = {
  TABLE: 'grupovehiculo'
};

const titleModule = 'Ajuste';
const titleSubModule = 'Vehículos';

const names = {
  ID: 'id',
  CAR_ID: 'veh_id',
  CAR_NAME: 'veh_nombre',
  CAR_BRAND: 'veh_marca',
  CAR_MODEL: 'veh_modelo',
  CAR_YEAR: 'veh_anio',
  GROUP_NAME: 'grupo_nombre',
  VTV_EXPIRATION: 'veh_vtv_vencimiento',
  GREEN_CARD_EXPIRATION: 'veh_cedula_verde_vencimiento',
  CAR_INSURANCE_COMPANY: 'veh_seguro_compania',
  CAR_INSURANCE_POLICY: 'veh_seguro_poliza',
  CAR_EXPIRATION_INSURANCE: 'veh_seguro_vencimiento',
  CAR_DESCRIPTION: 'veh_descripcion',
  CAR_MAXIMUM_SPEED: 'veh_velocidad_max',
  CAR_TYPE_ID: 'veh_tipo_id',
  CAR_OBSERVATION: 'veh_observaciones',
  ODO_VALUE: 'odo_valor',
  ODO_DATE: 'odo_fecha',
  TRK_ID: 'trk_id',
  ID_PROFILE_MAINTENANCE: 'man_perfil_id',
  PROFILE_NAME_MAINTENANCE: 'man_perfil_nombre',
  DRIVER_ID: 'chofer_id',
  DRIVER: 'chofer',
  DRIVER_NAME: 'chofer_nombre',
  DRIVER_LASTNAME: 'chofer_apellido',
  DRIVER_CAT_REGISTRATION: 'chofer_registro_categoria',
  DRIVER_REG_EXPIRATION: 'chofer_registro_vencimiento',
  DRIVER_FLEET_REG_CAT: 'chofer_registro_flota_categoria',
  DRIVER_FLEET_REG_EXPIRATION: 'chofer_registro_Flota_vencimiento',

  CLIENT_ID: 'cli_id',
  CLIENT_NAME: 'text',
  COMPANY_NAME: 'emp_nombre',
  CHECK: 'check'
};

const resourceDisplayName = {
  [names.ID]: 'Id',
  [names.CAR_ID]: 'Patente',
  [names.CAR_NAME]: 'Nombre',
  [names.CAR_BRAND]: 'Marca',
  [names.CAR_MODEL]: 'Modelo',
  [names.CAR_YEAR]: 'Año',
  [names.GROUP_NAME]: 'Grupo',
  [names.DRIVER_ID]: 'Chofer Id',
  [names.DRIVER]: 'Chofer',
  [names.DRIVER_NAME]: 'Chofer Nombre',
  [names.DRIVER_LASTNAME]: 'Chofer Apellido',
  [names.VTV_EXPIRATION]: 'VTV:',
  [names.GREEN_CARD_EXPIRATION]: 'Céd. Verde',
  [names.CLIENT_ID]: 'Id Cliente',
  [names.TRK_ID]: 'Traker',
  [names.DRIVER_ID]: 'Chofer Id',
  [names.CAR_INSURANCE_COMPANY]: 'Compañía Seguros',
  [names.CAR_INSURANCE_POLICY]: 'Nro. Poliza',
  [names.CAR_EXPIRATION_INSURANCE]: 'Venc. Seguro:',
  [names.CAR_DESCRIPTION]: 'Descripción',
  [names.CAR_MAXIMUM_SPEED]: 'Velocidad Máxima',
  [names.CAR_TYPE_ID]: 'Tipo',
  [names.DRIVER_CAT_REGISTRATION]: 'Reg. Cat. Chofer',
  [names.DRIVER_REG_EXPIRATION]: 'Reg. Vencimiento Chofer',
  [names.DRIVER_FLEET_REG_CAT]: 'Reg. Flota Cat. Chofer',
  [names.DRIVER_FLEET_REG_EXPIRATION]: 'Reg. Flota Venc. Chofer',
  [names.COMPANY_NAME]: 'Nombre Empresa',
  [names.PROFILE_NAME_MAINTENANCE]: 'Mantenimiento Perfil',
  [names.CAR_OBSERVATION]: 'Observaciones',
  [names.ODO_VALUE]: 'Odómetro',
  [names.ODO_DATE]: 'Fecha Odómetro',
  [names.CHECK]: 'Marcado',
  [names.CLIENT_NAME]: 'Nombre Cliente',

};

const fields = [
  // {
  //   key: 'index',
  //   label: '#'
  // },
  {
    key: names.GROUP_NAME,
    label: resourceDisplayName[names.GROUP_NAME],
    sortable: true
  },
  {
    key: names.CAR_ID,
    label: resourceDisplayName[names.CAR_ID],
    sortable: true
  },
  {
    key: names.CAR_NAME,
    label: resourceDisplayName[names.CAR_NAME],
    sortable: true
  },
  {
    key: names.CAR_BRAND,
    label: resourceDisplayName[names.CAR_BRAND],
    sortable: true
  },
  {
    key: names.TRK_ID,
    label: resourceDisplayName[names.TRK_ID],
    sortable: true
  },
  {
    key: names.DRIVER_NAME,
    label: resourceDisplayName[names.DRIVER_NAME],
    sortable: true
  },
  {
    key: names.DRIVER_LASTNAME,
    label: resourceDisplayName[names.DRIVER_LASTNAME],
    sortable: true
  },
  {
    key: 'show_details',
    label: '',
    thStyle: {width: '30px !important'}
  }
];

const fieldsPivot = [
  {
    key: names.CAR_ID,
    label: resourceDisplayName[names.CAR_ID],
    sortable: true
  },
  {
    key: names.CAR_NAME,
    label: resourceDisplayName[names.CAR_NAME],
    sortable: true
  },
  {
    key: names.DRIVER_NAME,
    label: resourceDisplayName[names.DRIVER_NAME],
    sortable: true
  },
  {
    key: names.DRIVER_LASTNAME,
    label: resourceDisplayName[names.DRIVER_LASTNAME],
    sortable: true
  },
  {
    key: names.CHECK,
    label: resourceDisplayName[names.CHECK],
    sortable: true,
    thStyle: {width: '30px !important'}
  }
];

const cliente = [
  { value: '1', text: 'Ingenea' },
  { value: '2', text: 'CSDNET' },
  { value: '3', text: 'GCBA' },
  { value: '4', text: 'SOLFLIX' },
  { value: '5', text: 'ROSSINI'},
  { value: '6', text: 'CHOPLIN'},
  { value: '7', text: 'VALTELLINA'},
  { value: '8', text: 'Ingeo'},
  { value: '9', text: 'Mun. Arenales'},
  { value: '10', text: 'ASSA'},
  { value: '11', text: 'Intecndt'},
  { value: '12', text: 'GMSUR'},
  { value: '13', text: 'Grupo Cammarata'},
]


export default {
  service,
  servicePivot,
  titleModule,
  titleSubModule,
  cliente,
  names,
  fields,
  fieldsPivot,
  methods: {
    /**
     * Resource Normalized name
     * @param regKey {String}
     * @returns {String}
     */
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    }
  }
}
