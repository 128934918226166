<template>
  <div>

    <div>
      <b-button-toolbar class="mb-4" justify key-nav>
        <b-button-group>
          <b-form-select v-model="selected" :options="options"></b-form-select>
        </b-button-group>

        <b-button-group>
          <b-button class="mr-1" @click="onPrev()">
            <b-icon icon="chevron-left"></b-icon>
          </b-button>
          <b-button class="mr-1" @click="onNext()">
            <b-icon icon="chevron-right"></b-icon>
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>

    <b-card v-if="selected>=0">
      <b-card-group>
        
        <b-card style="max-width: 20rem; border: 0px solid;">
          <b class="font-weight-bold"> Vehiculo: </b> <b class="font-weight-normal"> {{ options[selected].value }}  </b>
        </b-card>
        <b-card style="max-width: 20rem; border: 0px solid;">
          <b class="font-weight-bold"> Seg.Venc: </b> <b class="font-weight-normal"> {{ options[selected].text }}  </b>
        </b-card>

        <b-card style="max-width: 20rem; border: 0px solid;">
          <b class="font-weight-bold">Odómetro: </b> <b class="font-weight-normal"> {{ options[selected].value }}  </b>
        </b-card>
        <b-card style="max-width: 20rem; border: 0px solid;">
          <b class="font-weight-bold">Mant.Perfil: </b> <b class="font-weight-normal"> {{ options[selected].text }}  </b>
        </b-card>

      </b-card-group>
    </b-card>

    <hr>

    <h5>Relacion con Index {{stepIndex}}</h5>

    <b-progress :value="stepValue" class="mb-3"></b-progress>

    <b-button-toolbar class="mb-4" justify aria-label="Toolbar with button groups and input groups">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text class="d-none d-md-block">
            <b-icon icon="search"></b-icon>
          </b-input-group-text>
        </b-input-group-prepend>
        <b-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Filtrar. Ingrese texto..."
        ></b-input>
      </b-input-group>

      <b-button-group>
        <b-button class="mr-1" @click="onPrevStep()">
          <b-icon icon="chevron-left"></b-icon>
        </b-button>
        <b-button class="mr-1" @click="onNextStep()">
          <b-icon icon="chevron-right"></b-icon>
        </b-button>
      </b-button-group>
    </b-button-toolbar>

    <b-table
      striped
      hover
      stacked="sm"
      :items="items[stepIndex]"
      :fields="fields"
      sort-icon-left
      :filter="filter"
    >
      <template v-slot:cell(show_details)="row">
        <!-- <template v-if="rowCheck"> -->
          <b-form-checkbox @change="toggleRelation(row.item, row.index)">
          </b-form-checkbox>
        <!-- </template> -->
      </template>
    
    </b-table>

    <br>

    <div>
      <div class="mt-3">Opcion Seleccionada index: <strong>{{ selected }}</strong></div>
      <div v-if="selected>=0" class="mt-3">Opcion Vehiculo: <strong>{{ options[selected].text }}</strong></div>
      <div class="mt-3">Check Last Index Row: <strong>{{ index }}</strong></div>
      <div class="mt-3">Check Last Item Row: {{ item }}</div>
    </div>

  </div>
</template>

<script>
import dataTable from "./data";

export default {
  name: 'Pivot',
  data() {
    return {
      fields: dataTable.fields,
      items: dataTable.items,
      options: dataTable.options,
      filter: null,
      selected: 0,
      stepIndex: 0,
      item: '',
      index: ''
    }
  },
  computed: {
    stepValue() {
      let maxStep = this.items.length
      return 100 / maxStep * (this.stepIndex + 1) 
    }
  },
  methods: {
    toggleRelation(item, index) {
      this.item = item
      this.index = index
    },
    onPrev() {
      // console.log('prev')
      // TODO control de items lenght CERO
      if(this.selected > 0) {
        this.selected --
      } else {
        this.selected = this.options.length - 1
      }
    },
    onNext() {
      // console.log('next')
      // TODO control de items lenght CERO
      if(this.selected < this.options.length - 1) {
        this.selected ++
      } else {
        this.selected = 0
      }
    },
    onPrevStep() {
      if(this.stepIndex > 0) {
        this.stepIndex --
      } else {
        this.stepIndex = this.items.length - 1
      }
    },
    onNextStep() {
      if(this.stepIndex < this.items.length - 1) {
        this.stepIndex ++
      } else {
        this.stepIndex = 0
      }
    },
  }
}
</script>