// main.js
import Vue from 'vue';

// https://www.npmjs.com/package/vue-sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';

// import 'sweetalert2/dist/sweetalert2.min.css';

// https://github.com/sweetalert2/sweetalert2-themes/tree/master/bootstrap-4
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';

Vue.use(VueSweetalert2);

 