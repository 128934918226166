const service = {
  TABLE: 'mantenimiento-perfil',
  PRIMARY_KEY: 'man_perfil_id'
};
const titleModule = 'Ajuste';
const titleSubModule = 'Perfiles';
const names = {
  ID: 'man_perfil_id',
  PROFILE_NAME: 'man_perfil_nombre',
  PROFILE_DESCRIPTION: 'man_perfil_descripcion',
}

const resourceDisplayName = {
  [names.ID]: 'Id',
  [names.PROFILE_NAME]: 'Perfil',
  [names.PROFILE_DESCRIPTION]: 'Descripción',
  
}

const fields = [
  // {
  //   key: 'index',
  //   label: '#'
  // },
  {
    key: names.PROFILE_NAME,
    label: resourceDisplayName[names.PROFILE_NAME],
    sortable: true
  },
  {
    key: names.PROFILE_DESCRIPTION,
    label: resourceDisplayName[names.PROFILE_DESCRIPTION],
    sortable: true
  },
  
];

// const items =
// [
//   {
//     "id": "1",
//     "manperfilnombre": "Partner",
//     "manperfildescripcion": "Utilitario",
//   },
//   {
//     "id": "2",
//     "manperfilnombre": "Prueba",
//     "manperfildescripcion": "Prueba",
//   },
//   {
//     "id": "3",
//     "manperfilnombre": "Sin Definir",
//     "manperfildescripcion": "Campo por defecto para inicializar veh\u00edculos",
//   },
//   {
//     "id": "4",
//     "manperfilnombre": "Uno",
//     "manperfildescripcion": "Uno",
//   },
  
// ];

export default {
  //items,
  names,
  fields,
  service,
  titleModule,
  titleSubModule,
  methods: {
    /**
     * Resource Normalized name
     * @param regKey {String}
     * @returns {String}
     */
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    }
  }
}
