<template>
  <crud :service="service" :items="items" :fields="fields" :fileName="fileName" :isBusy="isBusy">

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:ItemFormLayout="{itemForm, isFormDisable}">

      <b-card bg-variant="light" >
        <!-- Fila 1 -->
        <h4>Detalles Del Chofer</h4>

        <b-form-row>
          <b-col sm="6">
            <b-form-group label-for="input-1" id="input-1">
              <label>{{ resourceDisplayName(names.FIRSTNAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-1" required type="text"
                v-model="itemForm[names.FIRSTNAME]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group label-for="input-2" id="input-2">
              <label>{{ resourceDisplayName(names.LASTNAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-2" required type="text"
                v-model="itemForm[names.LASTNAME]"
              ></b-form-input>
            </b-form-group>
          </b-col>
          
          <b-col sm="4" v-show="viewClient">
            <b-form-group label-for="input-3" id="input-3">
              <label>{{ resourceDisplayName(names.CLIENT) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-3" type="text"
                v-model="client_id"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <!-- Fila 2 -->
        <b-form-row>
          <b-col sm="4">
            <b-form-group label-for="input-4" id="input-4">
              <label>{{ resourceDisplayName(names.CELLPHONE) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-4"  required trim type="number"
                v-model="itemForm[names.CELLPHONE]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label-for="input-5" id="input-5">
              <label>{{ resourceDisplayName(names.PHONE) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-5" trim type="number"
                v-model="itemForm[names.PHONE]" 
              ></b-form-input>
            </b-form-group>
          </b-col>
          
          <b-col sm="4">
            <b-form-group label-for="input-6" id="input-6"
              :invalid-feedback="invalidFeedbackEmail(itemForm[names.EMAIL])"
              :state="stateEmail(itemForm[names.EMAIL])"
            >
              <label>{{ resourceDisplayName(names.EMAIL) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-6" required type="email"
                v-model="itemForm[names.EMAIL]" :state="stateEmail(itemForm[names.EMAIL])"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <!-- Fila 3 -->
        <b-form-row>
          <b-col sm="4">
            <b-form-group label-for="input-7" id="input-7">
              <label>{{ resourceDisplayName(names.STREET) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-7" type="text"
                v-model="itemForm[names.STREET]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label-for="input-8" id="input-8">
              <label>{{ resourceDisplayName(names.NUMBER) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-8" type="number"
                v-model="itemForm[names.NUMBER]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label-for="input-9" id="input-9">
              <label>{{ resourceDisplayName(names.DEPTO) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-9" type="text"
                v-model="itemForm[names.DEPTO]"
              ></b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>

        <!-- Fila 4 -->
        <b-form-row>
          <b-col sm="4">
            <b-form-group label-for="input-10" id="input-10">
              <label>{{ resourceDisplayName(names.FLOOR) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-10" type="text"
                v-model="itemForm[names.FLOOR]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label-for="input-11" id="input-11">
              <label>{{ resourceDisplayName(names.STATEID) }}</label>
              <select v-model="itemForm[names.STATEID]" :disabled="isFormDisable" 
                @change="provinceSelect($event.target.value)"  class="form-control">
                  <option v-for="provinces in province" v-bind:key="provinces" :value="provinces">
                    {{provinces}}
                  </option>
              </select>
             </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label-for="input-12" id="input-12">
              <label>{{ resourceDisplayName(names.CITY) }}</label>
              <select v-model="itemForm[names.CITY]" :disabled="isFormDisable" class="form-control" >
                  <option v-for="municipalities in municipality" v-bind:key="municipalities" :value="municipalities">
                    {{ municipalities }}
                  </option>
              </select>
             </b-form-group>
           </b-col>
        </b-form-row>

        <!-- Fila 4 -->
        <h4>Registros</h4>

        <b-form-row>
          <b-col sm="4">
            <b-form-group label-for="input-13" id="input-13">
              <label>{{ resourceDisplayName(names.REGID) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-13" required type="text"
                v-model="itemForm[names.REGID]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label-for="input-14" id="input-14">
              <label>{{ resourceDisplayName(names.REGCAT) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-14" required type="text"
                v-model="itemForm[names.REGCAT]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label-for="input-15" id="input-15">
              <label>{{ resourceDisplayName(names.REGEXP) }}</label>
              <b-form-datepicker :disabled="isFormDisable" id="input-15" required
                class="mb-2" placeholder="" hideHeader 
                today-button reset-button close-button
                label-today-button="Hoy" label-reset-button="Limpiar" label-close-button="Cerrar"
                labelHelp="Use el cursor para navegar"
                v-model="itemForm[names.REGEXP]"
              >
              </b-form-datepicker>
              <!-- <el-date-picker
                        :disabled="isFormDisable"
                        v-model="itemForm[names.REGEXP]"
                        type="date"
                        placeholder=""
                        :picker-options="pickerOptions"
              ></el-date-picker> -->
            </b-form-group>
          </b-col>
        </b-form-row>

        <!-- Fila 5 -->
        <b-form-row>
          <b-col sm="4">
            <b-form-group label-for="input-16" id="input-16">
              <label>{{ resourceDisplayName(names.REGFLEETID) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-16" type="text"
                v-model="itemForm[names.REGFLEETID]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label-for="input-17" id="input-17">
              <label>{{ resourceDisplayName(names.REGFLEETCAT) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-17" type="text"
                v-model="itemForm[names.REGFLEETCAT]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label-for="input-18" id="input-18">
              <label>{{ resourceDisplayName(names.REGFLEETEXP) }}</label>
              <b-form-datepicker :disabled="isFormDisable" id="input-18"
                class="mb-2" placeholder="" hideHeader 
                today-button reset-button close-button
                label-today-button="Hoy" label-reset-button="Limpiar" label-close-button="Cerrar"
                labelHelp="Use el cursor para navegar"
                v-model="itemForm[names.REGFLEETEXP]"
              >
              </b-form-datepicker>
              <!-- <el-date-picker
                        :disabled="isFormDisable"
                        v-model="itemForm[names.REGFLEETEXP]"
                        type="date"
                        placeholder=""
                        :picker-options="pickerOptions"
              ></el-date-picker> -->
            </b-form-group>
          </b-col>
        </b-form-row>

      </b-card>

    </template>

    <template v-slot:ItemTableLayout="{item}">
      <b-card-group deck>
        <b-card :header=resourceDisplayName(names.STREET) align="center">
          <b-card-text align="center"> {{ item[names.STREET]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.NUMBER) align="center">
          <b-card-text align="center"> {{ item[names.NUMBER]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.DEPTO) align="center">
          <b-card-text align="center"> {{ item[names.DEPTO]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.FLOOR) align="center">
          <b-card-text align="center"> {{ item[names.FLOOR]}} </b-card-text>
        </b-card>
      </b-card-group>
      <br>
      <b-card-group deck>
        <b-card :header=resourceDisplayName(names.STATE) align="center">
          <b-card-text align="center"> {{ item[names.STATE]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.CITY) align="center">
          <b-card-text align="center"> {{ item[names.CITY]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.CELLPHONE) align="center">
          <b-card-text align="center"> {{ item[names.CELLPHONE]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.PHONE) align="center">
          <b-card-text align="center"> {{ item[names.PHONE]}} </b-card-text>
        </b-card>
      </b-card-group>
      <br>
      <b-card-group deck>
        <b-card :header=resourceDisplayName(names.REGID) align="center">
          <b-card-text align="center"> {{ item[names.REGID]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.REGCAT) align="center">
          <b-card-text align="center"> {{ item[names.REGCAT]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.REGEXP) align="center"
          :border-variant=bgColor(item[names.REGEXP])
          :header-bg-variant=bgColor(item[names.REGEXP])
          :header-text-variant=bgTextColor(item[names.REGEXP]) >
          <b-card-text align="center"> 
            {{ item[names.REGEXP]}} ({{getDays(item[names.REGEXP])}} días)
          </b-card-text>
        </b-card>
      </b-card-group>
      <br>
      <b-card-group deck>
        <b-card :header=resourceDisplayName(names.REGFLEETID) align="center">
          <b-card-text align="center"> {{ item[names.REGFLEETID]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.REGFLEETCAT) align="center">
          <b-card-text align="center"> {{ item[names.REGFLEETCAT]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(names.REGFLEETEXP) align="center"
          :border-variant=bgColor(item[names.REGFLEETEXP])
          :header-bg-variant=bgColor(item[names.REGFLEETEXP])
          :header-text-variant=bgTextColor(item[names.REGFLEETEXP]) >
          <b-card-text align="center"> 
            {{ item[names.REGFLEETEXP]}} ({{getDays(item[names.REGFLEETEXP])}} días)
          </b-card-text>
        </b-card>
      </b-card-group>
      <br>
    </template>

  </crud>
</template>

<script>
import Crud from '@/components/Crud/Index';
import resources from '@/mixins/resourcesDriver'
import validation from '@/mixins/validation'
import customCell from '@/mixins/customCell'

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import choferService from "../../../../ingeo-mf-styleguide/src/services/chofer";
import authService from "../../../../ingeo-mf-styleguide/src/services/auth";

/// #else
import { choferService } from "@ingeo-mf/styleguide";
import { authService } from "@ingeo-mf/styleguide";

/// #endif

export default {
	components: {
    Crud
  },
  mixins: [resources, customCell],
  data () {
    return {
      titleModule: "Ajuste",
      titleSubModule: "Choferes",
      isViewForm: false,
      indexSelected: -1,
      fields: resources.fields,
      names: resources.names,
      emailRule: validation.emailRule,
      items: [],
      province: resources.province,
      municipality: resources.municipality,
      municipalitySelection: resources.municipalitySelection,
      service: resources.service,
      isBusy: false,
      client_id: null,
      viewClient: false
  
    }
  },
   mounted() {
     
      choferService.index().then((res) => {
        this.isBusy = true
        res.data.forEach(element => {
          this.items.push(element);
        });
        authService.userInfo().then((res) => {
        console.log("res", res)
        this.client_id = res.data.cli_id
        console.log("mounted -> this.client_id", this.client_id)
       });
   
         this.isBusy = false
         console.log("mounted ->  this.items",  this.items)
      });
  },
  computed: {
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`
    },
    fileName() {
      return `${this.titleModule}_${this.titleSubModule}`
    }
  },
  methods: {
    stateEmail (value) {
      if(!value) {
        return false
      } else {
        return this.emailRule.test(value)
      }
    },
    invalidFeedbackEmail(value) {
      if(!value) {
        return 'Ingrese Email'
      }
      return this.emailRule.test(value) ? '':'Ingrese un email valido'
    },
    provinceSelect($event){
      console.log($event)
      this.municipality = this.municipalitySelection[$event]
      console.log(this.municipality)
    }
  }
}    
</script>
