const emailRule = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const passRules = [
  { message:'1 letra minúscula requerida.', regex:/[a-z]+/ },
  { message:"1 letra mayúscula requerida.",  regex:/[A-Z]+/ },
  { message:"8 caracteres mínimo.", regex:/.{8,}/ },
  { message:"1 número requerido.", regex:/[0-9]+/ }
]

export default {
  emailRule,
  passRules
};

