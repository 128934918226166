const service = {
  TABLE: 'chofer',
  PRIMARY_KEY: 'chofer_id'
};
const titleModule = 'Ajuste';
const titleSubModule = 'Choferes';

const names = {
  ID: 'chofer_id',
  FIRSTNAME: 'chofer_nombre',
  LASTNAME: 'chofer_apellido',
  CELLPHONE: 'chofer_celular',
  PHONE: 'chofer_telefono',
  EMAIL: 'chofer_mail',
  REGID: 'chofer_registro_numero',
  REGEXP: 'chofer_registro_vencimiento',
  REGCAT: 'chofer_registro_categoria',
  REGFLEETID: 'chofer_registro_flota_numero',
  REGFLEETEXP: 'chofer_registro_flota_vencimiento',
  REGFLEETCAT: 'chofer_registro_flota_categoria',
  CLIENT: 'cli_id',
  STREET: 'chofer_calle',
  NUMBER: 'chofer_numero',
  DEPTO: 'chofer_depto',
  FLOOR: 'chofer_piso',
  CITY: 'loc_nombre',
  CITYID: 'loc_id',
  STATE: 'prov_nombre',
  STATEID: 'prov_id'
}

const resourceDisplayName = {
  [names.ID]: 'Id',
  [names.FIRSTNAME]: 'Nombre',
  [names.LASTNAME]: 'Apellido',
  [names.CELLPHONE]: 'Celular',
  [names.PHONE]: 'Teléfono',
  [names.EMAIL]: 'Email',
  [names.REGID]: 'Registo',
  [names.REGEXP]: 'Registro Venc',
  [names.REGCAT]: 'Registro Cat',
  [names.REGFLEETID]: 'Reg. Flota',
  [names.REGFLEETEXP]: 'Reg. Flota Venc',
  [names.REGFLEETCAT]: 'Reg. Flota Cat',
  [names.CLIENT]: 'Cliente',
  [names.STREET]: 'Calle',
  [names.NUMBER]: 'Número',
  [names.DEPTO]: 'Depto',
  [names.FLOOR]: 'Piso',
  [names.CITY]: 'Ciudad',
  [names.CITYID]: 'Ciudad Id',
  [names.STATE]: 'Provincia',
  [names.STATEID]: 'Prov Id'
}

const fields = [
  // {
  //   key: 'index',
  //   label: '#'
  // },
  {
    key: names.FIRSTNAME,
    label: resourceDisplayName[names.FIRSTNAME],
    sortable: true
  },
  {
    key: names.LASTNAME,
    label: resourceDisplayName[names.LASTNAME],
    sortable: true
  },
  {
    key: names.CELLPHONE,
    label: resourceDisplayName[names.CELLPHONE],
    sortable: true
  },
  {
    key: names.EMAIL,
    label: resourceDisplayName[names.EMAIL],
    sortable: true
  },
  {
    key: names.REGCAT,
    label: resourceDisplayName[names.REGCAT],
    sortable: true
  },
  {
    key: names.REGEXP,
    label: resourceDisplayName[names.REGEXP],
    sortable: true,
    customCell: true,
    formatter: 'customDateStatus'         // OP1 call function por mixims
    // formatter: (value, key, item) => {      // OP2 valido solo para recalcular/procesar "value"
    //   let cell = `<div style="background-color: `+`getDaysStatus(getDays(${value}))`+` "> 
    //                 ${value} (`+`getDays(${value}`+`) días)
    //               </div>`
    //   return cell
    // }
  },
  {
    key: 'show_details',
    label: '',
    thStyle: {width: '30px !important'}
  }
]

const province = ['Buenos Aires','CABA', 'Catamarca', 'Chaco', 'Chubut' , 'Córdoba', 'Corrientes',         
                    'Entre Ríos', 'Formosa','Jujuy','La Pampa','La Rioja','Mendoza','Misiones','Neuquén',            
                    'Río Negro','Salta','San Juan','San Luis','Santa Cruz','Santa Fe','Santiago del Estero',
                    'Tierra del Fuego', 'Tucumán'
                  ]

const municipality = []

const municipalitySelection = {
            'Buenos Aires': ['Adolfo Alsina', 'Adolfo Gonzales Chaves', ' Alberti', 'Almirante Brown', 'Arrecifes', 'Avellaneda', 'Bahía Blanca','Bragado','Ramallo', 'San Nicolás de los Arroyos'],        
             'CABA' : ['Retiro', 'San Nicolás', 'Puerto Madero', 'San Telmo',' Montserrat', 'Constitución'],                
             'Catamarca': ['Ambato', 'Ancasti', 'Andalgalá', 'Antofagasta de la Sierra', 'Belén', 'Capayán'],          
             'Chaco': ['Almirante Brown','Bermejo','Chacabuco', 'Comandante Fernández'],               
            'Chubut' : ['Biedma', 'Cushamen', 'Escalante', 'Florentino Ameghino', 'Futaleufú'] ,            
            'Córdoba': ['Almafuerte', 'Alta Gracia', 'Arroyito', 'Bell Ville', 'Colonia Caroya', 'Córdoba', 'Coronel Moldes'],            
            'Corrientes':['Bella Vista', 'Berón de Astrada', 'Bonpland', 'Caá Catí', 'Carolina', 'Chavarría', 'Ciudad de Corrientes', 'Colonia Carlos'],          
            'Entre Ríos': ['Colón','Concordia', 'Diamante', 'Federación', 'Federal', 'Feliciano','Gualeguay'],          
            'Formosa': [],            
            'Jujuy': [],              
            'La Pampa': [],            
            'La Rioja': [],            
            'Mendoza': [],            
            'Misiones':[],            
            'Neuquén':[],          
            'Río Negro':[],           
            'Salta':[],               
            'San Juan':[],           
            'San Luis':[],           
            'Santa Cruz':[],          
            'Santa Fe':[],            
            'Santiago del Estero':[], 
            'Tierra del Fuego':[],   
            'Tucumán':[],            
      }
export default {
  province,
  municipality,
  municipalitySelection,
  service,
  titleModule,
  titleSubModule,
  names,
  fields,
  methods: {
    /**
     * Resource Normalized name
     * @param regKey {String}
     * @returns {String}
     */
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    }
  }
}
