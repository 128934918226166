<template>

  <crud :service="service" :items="items" :fields="fields" :fileName="fileName" :isBusy="isBusy">

    <template v-slot:Title>
      <div><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:ItemFormLayout="{itemForm, isFormDisable}">
      <b-card bg-variant="light" >
        <!-- Fila 1 -->
        <b-form-row>
          <b-col sm="3">
            <b-form-group label-for="input-1"
                :invalid-feedback="invalidFeedbackVeh(itemForm[namesVehiculo.CAR_ID])"
                :state="stateVeh(itemForm[namesVehiculo.CAR_ID])"
              >
              <label>{{ resourceDisplayName(namesVehiculo.CAR_ID) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-1" required trim type="text"
                v-model="itemForm[namesVehiculo.CAR_ID]" :state="stateVeh(itemForm[namesVehiculo.CAR_ID])"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label-for="input-2">
               <label>{{ resourceDisplayName(namesVehiculo.CAR_NAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-2" type="text"
                v-model="itemForm[namesVehiculo.CAR_NAME]"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label-for="input-2">
               <label>{{ resourceDisplayName(namesVehiculo.CAR_BRAND) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-2" type="text"
                v-model="itemForm[namesVehiculo.CAR_BRAND]"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label-for="input-3">
              <label>{{ resourceDisplayName(namesVehiculo.CAR_MODEL) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-3" type="text"
                v-model="itemForm[namesVehiculo.CAR_MODEL]"></b-form-input>
            </b-form-group>
          </b-col>
           
        </b-form-row>

        <!-- Fila 2 -->
        <b-form-row>
          
         <b-col sm="3">
            <b-form-group  label-for="input-5" id="input-5">
              <label>{{ resourceDisplayName(namesVehiculo.GREEN_CARD_EXPIRATION) }}</label>
              <b-form-datepicker :disabled="isFormDisable" id="input-5"
                class="mb-2" placeholder="" hideHeader
                today-button reset-button close-button
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                label-today-button="Hoy" label-reset-button="Limpiar" label-close-button="Cerrar"
                labelHelp="Use el cursor para navegar"
                v-model="itemForm[namesVehiculo.GREEN_CARD_EXPIRATION]"
              >
              </b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label-for="input-6">
             <label>{{ resourceDisplayName(namesVehiculo.CAR_INSURANCE_COMPANY) }}</label>
             <b-form-input :disabled="isFormDisable" id="input-6" type="text"
                v-model="itemForm[namesVehiculo.CAR_INSURANCE_COMPANY]"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label-for="input-7">
              <label>{{ resourceDisplayName(namesVehiculo.CAR_INSURANCE_POLICY) }}</label>
             <b-form-input :disabled="isFormDisable" id="input-7"  type="text"
                v-model="itemForm[namesVehiculo.CAR_INSURANCE_POLICY]"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group  label-for="input-8" id="input-8">
            <label>{{ resourceDisplayName(namesVehiculo.CAR_EXPIRATION_INSURANCE) }}</label>
              <b-form-datepicker :disabled="isFormDisable" id="input-8"
                class="mb-2" placeholder="" hideHeader
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                today-button reset-button close-button
                label-today-button="Hoy" label-reset-button="Limpiar" label-close-button="Cerrar"
                labelHelp="Use el cursor para navegar"
                v-model="itemForm[namesVehiculo.CAR_EXPIRATION_INSURANCE]"
              >
              </b-form-datepicker>
            </b-form-group>
          </b-col>
          
        </b-form-row>

        <!-- Fila 3 -->
        <b-form-row>
          <b-col sm="3">
            <b-form-group label-for="input-4">
              <label>{{ resourceDisplayName(namesVehiculo.CAR_YEAR) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-4" type="text"
                v-model="itemForm[namesVehiculo.CAR_YEAR]"></b-form-input>
            </b-form-group>
          </b-col>
         <b-col sm="3">
            <b-form-group label-for="input-9">
              <label>{{ resourceDisplayName(namesVehiculo.DRIVER_NAME) }}</label>
              <b-form-select  :disabled="isFormDisable" id="input-9"  v-model="itemForm[namesVehiculo.DRIVER_ID]" :options="choferNombre" required trim ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label-for="input-10">
              <label>{{ resourceDisplayName(namesVehiculo.DRIVER_LASTNAME) }}</label>
              <b-form-select  :disabled="isFormDisable" id="input-10"  v-model="itemForm[namesVehiculo.DRIVER_ID]" :options="choferApellido" required trim ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label-for="input-11">
              <label>{{ resourceDisplayName(namesVehiculo.PROFILE_NAME_MAINTENANCE) }}</label>
              <b-form-select  :disabled="isFormDisable" id="input-11" v-model="itemForm[namesVehiculo.ID_PROFILE_MAINTENANCE]" :options="mantenimientoPerfil"  required trim></b-form-select>
            </b-form-group>
          </b-col>
         
      </b-form-row>

      <!-- Fila 4 -->
        <b-form-row>
          <b-col sm="3">
            <b-form-group label-for="input-12">
              <label>{{ resourceDisplayName(namesVehiculo.CAR_TYPE_ID) }}</label>
              <b-form-select  :disabled="isFormDisable" id="input-12"  v-model="itemForm[namesVehiculo.CAR_TYPE_ID]" :options="vehiculoTipo" ></b-form-select>
            </b-form-group>
          </b-col>
           <b-col sm="3">
            <b-form-group label-for="input-13">
              <label>{{ resourceDisplayName(namesVehiculo.CAR_DESCRIPTION) }}</label>
             <b-form-input :disabled="isFormDisable" id="input-13" type="text"
                v-model="itemForm[namesVehiculo.CAR_DESCRIPTION]"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label-for="input-14">
             <label>{{ resourceDisplayName(namesVehiculo.CLIENT_ID) }}</label>
              <b-form-select  :disabled="isFormDisable" id="input-14"  v-model="itemForm[namesVehiculo.CLIENT_ID]" :options="cliente"  required trim></b-form-select>
            </b-form-group>
          </b-col>
           <b-col sm="3">
            <b-form-group label-for="input-15">
              <label>{{ resourceDisplayName(namesVehiculo.CAR_MAXIMUM_SPEED) }}</label>
             <b-form-input :disabled="isFormDisable" id="input-15"  type="text"
                v-model="itemForm[namesVehiculo.CAR_MAXIMUM_SPEED]"></b-form-input>
            </b-form-group>
          </b-col>
         
        </b-form-row>
        <!-- Fila 5 -->
        <b-form-row>
           <b-col sm="3">
            <b-form-group label-for="input-16">
             <label>{{ resourceDisplayName(namesVehiculo.TRK_ID) }}</label>
              <b-form-select  :disabled="isFormDisable" id="input-16"  v-model="itemForm[namesVehiculo.TRK_ID]" :options="tracker"  required trim></b-form-select>
            </b-form-group>
          </b-col>
         
         <b-col sm="9">
            <b-form-group label-for="input-17">
              <label>{{ resourceDisplayName(namesVehiculo.CAR_OBSERVATION) }}</label>
              <!-- <b-form-input :disabled="isFormDisable" id="input-14"
               v-model="itemForm[names.CAR_OBSERVATION]"></b-form-input> -->
              <b-form-textarea :disabled="isFormDisable" id="input-17"
                v-model="itemForm[namesVehiculo.CAR_OBSERVATION]"
                placeholder="Ingrese Observaciones..."
                rows="2" max-rows="3">
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-card>
   </template>

    <template v-slot:ItemTableLayout="{item}">
      <b-card-group deck>
        <b-card :header=resourceDisplayName(namesVehiculo.CAR_YEAR) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.CAR_YEAR]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.CAR_MODEL) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.CAR_MODEL]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.CAR_TYPE_ID) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.CAR_TYPE_ID]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.CAR_MAXIMUM_SPEED) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.CAR_MAXIMUM_SPEED]}} </b-card-text>
        </b-card>
      </b-card-group>
      <br>
      <b-card-group deck>
        <b-card :header=resourceDisplayName(namesVehiculo.CAR_INSURANCE_POLICY) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.CAR_INSURANCE_POLICY]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.CAR_EXPIRATION_INSURANCE) align="center"
          :border-variant=bgColor(item[namesVehiculo.CAR_EXPIRATION_INSURANCE])
          :header-bg-variant=bgColor(item[namesVehiculo.CAR_EXPIRATION_INSURANCE])
          :header-text-variant=bgTextColor(item[namesVehiculo.CAR_EXPIRATION_INSURANCE]) >
          <b-card-text align="center">
            {{ item[namesVehiculo.CAR_EXPIRATION_INSURANCE]}} ({{getDays(item[namesVehiculo.CAR_EXPIRATION_INSURANCE])}} días)
          </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.CAR_INSURANCE_COMPANY) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.CAR_INSURANCE_COMPANY]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.GREEN_CARD_EXPIRATION) align="center"
          :border-variant=bgColor(item[namesVehiculo.GREEN_CARD_EXPIRATION])
          :header-bg-variant=bgColor(item[namesVehiculo.GREEN_CARD_EXPIRATION])
          :header-text-variant=bgTextColor(item[namesVehiculo.GREEN_CARD_EXPIRATION]) >
          <b-card-text align="center">
            {{ item[namesVehiculo.GREEN_CARD_EXPIRATION]}} ({{getDays(item[namesVehiculo.GREEN_CARD_EXPIRATION])}} días)
          </b-card-text>
        </b-card>
      </b-card-group>
      <br>
      <b-card-group deck>
        <b-card :header=resourceDisplayName(namesVehiculo.VTV_EXPIRATION) align="center"
          :border-variant=bgColor(item[namesVehiculo.VTV_EXPIRATION])
          :header-bg-variant=bgColor(item[namesVehiculo.VTV_EXPIRATION])
          :header-text-variant=bgTextColor(item[namesVehiculo.VTV_EXPIRATION]) >
          <b-card-text align="center">
            {{ item[namesVehiculo.VTV_EXPIRATION]}} ({{getDays(item[namesVehiculo.VTV_EXPIRATION])}} días)
          </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.PROFILE_NAME_MAINTENANCE) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.PROFILE_NAME_MAINTENANCE]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.ODO_VALUE) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.ODO_VALUE]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.ODO_DATE) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.ODO_DATE]}} </b-card-text>
        </b-card>
      </b-card-group>
      <br>
      <b-card-group deck>
        <b-card :header=resourceDisplayName(namesVehiculo.CAR_DESCRIPTION) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.CAR_DESCRIPTION]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.CAR_OBSERVATION) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.CAR_OBSERVATION]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.CLIENT_ID) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.CLIENT_ID]}} </b-card-text>
        </b-card>
        <b-card :header=resourceDisplayName(namesVehiculo.CLIENT_NAME) align="center">
          <b-card-text align="center"> {{ item[namesVehiculo.CLIENT_NAME]}} </b-card-text>
        </b-card>
      </b-card-group>
    </template>

  </crud>
</template>

<script>
import Crud from '@/components/Crud/Index';
import resourcesCars from '@/mixins/resourcesCars'
import resourcesUser from '@/mixins/resourcesUser'
import customCell from '@/mixins/customCell'

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import vehiculoService from "../../../../ingeo-mf-styleguide/src/services/vehiculo";
import choferService from "../../../../ingeo-mf-styleguide/src/services/chofer";
import vehiculoTipoService from "../../../../ingeo-mf-styleguide/src/services/vehiculo-tipo";
import mantenimientoPerfilService from "../../../../ingeo-mf-styleguide/src/services/mantenimiento-perfil";
import trackerService from "../../../../ingeo-mf-styleguide/src/services/tracker";
// import userService from "../../../../ingeo-mf-styleguide/src/services/user";
import grupoService from "../../../../ingeo-mf-styleguide/src/services/grupo";
import grupoVehiculoService from "../../../../ingeo-mf-styleguide/src/services/grupo-vehiculo";
/// #else
import { vehiculoService } from "@ingeo-mf/styleguide";
import { choferService } from "@ingeo-mf/styleguide";
import { vehiculoTipoService } from "@ingeo-mf/styleguide";
import { mantenimientoPerfilService } from "@ingeo-mf/styleguide";
import { trackerService } from "@ingeo-mf/styleguide";
// import { userService } from "@ingeo-mf/styleguide";
import { grupoService } from "@ingeo-mf/styleguide";
import { grupoVehiculoService } from "@ingeo-mf/styleguide";
/// #endif


export default {
  name: 'ConfigVehiculos',
  mixins: [resourcesCars, customCell],
	components: {
    Crud
    // [DatePicker.name]: DatePicker,
  },
  data () {
    return {
      
      titleModule: resourcesCars.titleModule,
      titleSubModule: resourcesCars.titleSubModule,
      isViewForm: false,
      indexSelected: -1,
      isBusy: false,

      // fields: resourcesCars.fields,
      //names: resourcesCars.names,
      service: resourcesCars.service,

      namesVehiculo: resourcesCars.names,
      // namesUsuario: resourcesUser.names,
      fields: [],
      items: [],
      choferNombre: [],
      choferApellido: [],
      mantenimientoPerfil: [],
      vehiculoTipo: [],
      tracker: [],
      cliente: resourcesCars.cliente,
      grupos: [],
      gruposVeh: [],
    }
  },
  mounted () {
    let choferes = []
    let client = []
    let groupVeh = []
     
    choferService.index().then((res) => {
      this.isBusy = true
      res.data.forEach(element => {
          this.choferNombre.push({ value: element.chofer_id, text: element.chofer_nombre });
          this.choferApellido.push({ value: element.chofer_id, text: element.chofer_apellido });
          choferes.push(element);
        }); 

        grupoService.index().then((res) => {
          res.data.forEach(element => {
            this.grupos.push(element);
            // console.log("mounted -> this.grupos", this.grupos)
          });
         grupoVehiculoService.index().then((res)=>{
            res.data.forEach(element => {
            this.gruposVeh.push(element);
            let idg = this.grupos.findIndex(el => el.grupo_id === element.grupo_id)
            if (idg !== -1) Object.assign(element, this.grupos[idg])
         });
                 
        this.cliente.forEach(element => 
        client.push(element));
  
        vehiculoService.index().then((res) => {
        res.data.map(elem => {
          let idxChofer = choferes.findIndex(el => el[this.namesVehiculo.DRIVER_ID] === elem[this.namesVehiculo.DRIVER_ID])
          // let idxGrupo = this.grupos.findIndex(el => el[this.namesVehiculo.CLIENT_ID] === elem[this.namesVehiculo.CLIENT_ID])
          let idxGrupo = this.gruposVeh.findIndex(el => el.veh_id === elem[this.namesVehiculo.CAR_ID] )
          let idxCliente = client.findIndex(el => el.value === elem[this.namesVehiculo.CLIENT_ID].toString() )
         
          if (idxCliente !== -1) Object.assign(elem, client[idxCliente])
          if (idxGrupo !== -1) Object.assign(elem, this.gruposVeh[idxGrupo])
          if (idxChofer !== -1) Object.assign(elem, choferes[idxChofer])
             
        });
        res.data.forEach(element => {
            this.items.push(element);
         });
        resourcesCars.fields.forEach(element => {
           this.fields.push(element);
         });

        console.log("mounted -> this.items", this.items)
         
       vehiculoTipoService.index().then((res) => {
          res.data.forEach(element => {
          this.vehiculoTipo.push({ value: element.veh_tipo_id, text: element.veh_tipo_nombre});
        });

        mantenimientoPerfilService.index().then((res) => {
          res.data.forEach(element => {
            this.mantenimientoPerfil.push({ value: element.man_perfil_id, text: element.man_perfil_nombre});
          });

          trackerService.index().then((res) => {
            res.data.forEach(element => {
              this.tracker.push({ value: element.trk_id, text: element.trk_id});
            });
            this.isBusy = false
              });
            });
            });
          });
        });
      });
     });
    //     });
    //   });
     
    // }); 
   },
  computed: {
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`
    },
    fileName() {
      return `${this.titleModule}_${this.titleSubModule}`
    },
  },
  methods: {
    stateVeh (value) {
      if(!value) {
        return false
      } else {
        return value.length >= 6 ? true : false
      }
    },
    invalidFeedbackVeh(value) {
      if(!value) {
        return 'Ingrese patente'
      }
      if (value.length >= 6) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 6 caracteres'
      } else {
        return 'Ingrese patente'
      }
    }

// TODO control de ingresos y fechas de vencimiento
// TODO datos a mostrar / editar

  },
}
</script>
