const moduleName = "Ajustes";
const menuItems = [
  {
    id: 1,
    title: "Sistema",
    type: "dropdown",
    items: [
      { id: 11, type: "header", name: "Flota", url: "" },
      { id: 12, type: "link", name: "Vehículos", url: "/config/vehiculos" },
      { id: 13, type: "link", name: "Choferes", url: "/config/choferes" },
      { id: 14, type: "divider" },
      { id: 15, type: "header", name: "Grupos", url: "" },
      { id: 16, type: "link", name: "Grupos", url: "/config/grupos" },
      { id: 17, type: "link", name: "Miembros", url: "/config/grupospivot" },
    ],
  },
  {
    id: 2,
    title: "Mantenimiento",
    type: "dropdown",
    items: [
      { id: 21, type: "header", name: "Mantenimiento", url: "" },
      { id: 22, type: "link", name: "Perfiles", url: "/config/mantperfiles" },
      { id: 23, type: "link", name: "Items", url: "/config/mantitems" },
      { id: 24, type: "link", name: "Relaciones", url: "/config/pivot" },
    ],
  },
  {
    id: 3,
    title: "Zonas",
    type: "dropdown",
    items: [
      { id: 31, type: "header", name: "Menu2-Header", url: "" },
      { id: 32, type: "link", name: "__Menu2-1", url: "/config" },
      { id: 33, type: "divider" },
      { id: 34, type: "link", name: "__Menu2-1", url: "/config" },
    ],
  },
  {
    id: 4,
    title: "Alertas",
    type: "dropdown",
    items: [
      { id: 41, type: "header", name: "Alertas", url: "" },
      { id: 42, type: "link", name: "__Perfiles", url: "/config" },
      { id: 43, type: "link", name: "__Relaciones", url: "/config" },
    ],
  },
  {
    id: 5,
    title: "Usuarios",
    type: "dropdown",
    items: [
      { id: 51, type: "header", name: "Usuarios", url: "" },
      { id: 52, type: "link", name: "Usuarios", url: "/config/usuarios" },
      { id: 53, type: "link", name: "__Reset Pass", url: "/config" },
    ],
  },
  {
    id: 6,
    title: "Admin",
    type: "dropdown",
    items: [
      { id: 61, type: "header", name: "Ingenea", url: "" },
      { id: 62, type: "link", name: "Clientes", url: "/config" },
      { id: 63, type: "link", name: "Trackers", url: "/config/tracker" },
      { id: 64, type: "link", name: "Chips", url: "/config/chips" },
      { id: 65, type: "link", name: "__1er Usuario", url: "/config" },
    ],
  },
  // { id: 3, title: "Login", type: "link", url: "/login" },
];
export default { moduleName, menuItems };
