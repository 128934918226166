const dateTextColor = {
  DARK: 'black',
  LIGHT: 'white'
}

const dateBgColor = {
  SUCCESS: '#28a745', //'lightgreen', >>> variant success
  WARNING: '#ffc107', // 'yellow',>>> variant warning
  DANGER: '#dc3545'  // 'lightsalmon'>>> variant dander
}

const colorVariant = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info'
}

export default {
  methods: {
    bgColor(value) {
      return this.getDaysVariant(value).bg
    },
    bgTextColor(value) {
      return this.getDaysVariant(value).text

    },
    getDays(date) { 
      let oneDay = 1000 * 60 * 60 * 24  // One day Time in ms (milliseconds)
      return Math.round(( new Date(date) - new Date() ) / oneDay)
    },
    getDaysStatus(date) {
      let days = this.getDays(date) 
      if (days < 0) {                 // Se vencio el plazo
        return {text: dateTextColor.LIGHT, bg: dateBgColor.DANGER}
      } else if (days < 30) {         // Dentro de los 30 dias de venc
        return {text: dateTextColor.LIGHT, bg: dateBgColor.WARNING}
      }
      return {text: dateTextColor.DARK, bg: dateBgColor.SUCCESS}      // Fecha normal
    },
    getDaysVariant(date) {
      let days = this.getDays(date)  
      if (days < 0) {                 // Se vencio el plazo
        return {text: dateTextColor.LIGHT, bg: colorVariant.DANGER}
      } else if (days < 30) {         // Dentro de los 30 dias de venc
        return {text: dateTextColor.LIGHT, bg: colorVariant.WARNING}
      }
      return {text: dateTextColor.DARK, bg: colorVariant.SUCCESS}      // Fecha normal
    },
    customDateStatus(value) {
      let days = this.getDays(value)
      let color = this.getDaysStatus(value)
      let cell = `<div style="background-color: ${color.bg};
                              text-align:center;
                              padding: 0px 20px;
                              margin: 0px 20px;
                              border-radius: 25px;
                              color: ${color.text}"> 
                              ${value} (${days} días)
                  </div>`
      return cell
    },
  }
};

