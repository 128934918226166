<template>
  <crud :service="service" :items="items" :fields="fields" :fileName="fileName" :isBusy="isBusy">

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:ItemFormLayout="{itemForm, isFormDisable}">

      <b-card bg-variant="light" >
        <!-- Fila 1 -->
        <b-form-row>
           <b-col sm="6" v-show="viewId">
            <b-form-group label-for="input-2" id="input-2">
              <label>{{ resourceDisplayName(names.ID) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-2" type="text"
                v-model="itemForm[names.ID]"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label-for="input-1" id="input-1"
                :invalid-feedback="invalidFeedbackProfile(itemForm[names.PROFILE_NAME])"
                :state="stateProfile(itemForm[names.PROFILE_NAME])"
              >
              <label>{{ resourceDisplayName(names.PROFILE_NAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-1" required type="text"
                v-model="itemForm[names.PROFILE_NAME]" :state="stateProfile(itemForm[names.PROFILE_NAME])"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label-for="input-2" id="input-2">
              <label>{{ resourceDisplayName(names.PROFILE_DESCRIPTION) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-2" type="text"
                v-model="itemForm[names.PROFILE_DESCRIPTION]"
              ></b-form-input>
            </b-form-group>
          </b-col>
         </b-form-row>
      </b-card>

    </template>

  </crud>
</template>

<script>
import Crud from '@/components/Crud/Index';
import resources from '@/mixins/resourcesProfMaint'
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import mantenimientoPerfilService from "../../../../ingeo-mf-styleguide/src/services/mantenimiento-perfil";
/// #else
import { mantenimientoPerfilService } from "@ingeo-mf/styleguide";
/// #endif

export default {
  name: 'ConfigMantPerfiles',
  mixins: [resources],
	components: {
    Crud
  },
  data () {
    return {
      titleModule: resources.titleModule,
      titleSubModule: resources.titleSubModule,
      isViewForm: false,
      indexSelected: -1,
      fields: resources.fields,
      names: resources.names,
      items: [], //resources.items,
      service: resources.service,
      // mantenimientoPerfil: [],
      isBusy: false,
      viewId: false
    }
  },
  mounted() {
    mantenimientoPerfilService.index().then((res) => {
       this.isBusy = true
          res.data.forEach(element => {
            this.items.push(element);
          
          });
          this.isBusy = false
          console.log("mounted -> this.items", this.items)
    });
  },
  computed: {
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`
    },
    fileName() {
      return `${this.titleModule}_${this.titleSubModule}`
    }
  },
 

  methods: {
    stateProfile (value) {
      if(!value) {
        return false
      } else {
        return value.length >= 4 ? true : false
      }
    },
    invalidFeedbackProfile(value) {
      if(!value) {
        return 'Ingrese Nombre del Perfil'
      }
      if (value.length >= 4) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 4 caracteres'
      } else {
        return 'Ingrese Nombre del Perfil'
      }
    }

    // TODO control de ingresos
    // TODO datos a mostrar / editar
  }
}
</script>
