const fields = [
  {
    key: 'veh_id',
    sortable: true,
    label: 'Patente'
  },
  {
    key: 'veh_marca',
    sortable: true,
    label: 'Marca'
  },
  {
    key: 'veh_modelo',
    sortable: true,
    label: 'Modelo'
  },
  {
    key: 'veh_anio',
    sortable: true,
    label: 'Año'
  },
  {
    key: 'grupo_nombre',
    sortable: true,
    label: 'Grupo'
  },
  {
    key: 'chofer_nombre',
    sortable: true,
    label: 'Chofer Nombre'
  },
  {
    key: 'chofer_apellido',
    sortable: true,
    label: 'Chofer Apellido'
  },
  {
    key: 'veh_tipo_nombre',
    sortable: true,
    label: 'Tipo'
  },
  {
    key: 'veh_anio',
    sortable: true
  },
  {
    key: 'chofer_id',
    sortable: true
  },
  {
    key: 'show_details',
    label: '',
    thStyle: {width: '30px !important'}
  }
];

const items = [
  [{ veh_id: 'AE139KF', veh_marca: 'CITROEN', veh_nombre: '', veh_modelo: 'Berlingo', veh_vtv_vencimiento: '', veh_cedula_verde_vencimiento: '', cli_id: 36, trk_id: 746, chofer_id: 8, veh_anio: '2020', veh_seguro_compania: '', veh_seguro_poliza: '', veh_seguro_vencimiento: '', veh_descripcion: '', veh_velocidad_max: '110', veh_tipo_id: 3, veh_tipo_nombre: 'Furgon', chofer_nombre: 'Sin asignar', chofer_apellido: '---', chofer_registro_categoria: '', chofer_registro_vencimiento: '2011-01-01', chofer_registro_flota_categoria: '', chofer_registro_Flota_vencimiento: '2011-01-01', emp_nombre: 'Coop de Electricidad y Servicios Anexos', man_perfil_id: 3, man_perfil_nombre: 'Sin Definir !', grupo_nombre: 'Flota'},
  { veh_id: 'EQN506', veh_marca: 'FORD', veh_nombre: '', veh_modelo: 'F4000', veh_vtv_vencimiento: '', veh_cedula_verde_vencimiento: '', cli_id: 36, trk_id: 720, chofer_id: 8,veh_anio: '2004', veh_seguro_compania: '', veh_seguro_poliza: '',veh_seguro_vencimiento: '', veh_descripcion: 'Hidroelevador Hidro Grubert Bl15CLA 2017', veh_velocidad_max: '90', veh_tipo_id: 5, veh_tipo_nombre: 'Hidro', chofer_nombre:'Sin asignar', chofer_apellido: '---',chofer_registro_categoria: '', chofer_registro_vencimiento: '2011-01-01', chofer_registro_flota_categoria: '', chofer_registro_flota_vencimiento: '2011-01-01', emp_nombre: 'Coop de Electricidad y Servicios Anexos', man_perfil_id: 3, man_perfil_nombre: 'Sin Definir !', grupo_nombre: 'Flota'},
  { veh_id: 'VHE182', veh_marca: 'FORD', veh_nombre: '', veh_modelo: 'F100', veh_vtv_vencimiento: '', veh_cedula_verde_vencimiento: '', cli_id: 36, trk_id: 712, chofer_id: 8,veh_anio: '1994', veh_seguro_compania: '', veh_seguro_poliza: '',veh_seguro_vencimiento: '', veh_descripcion: 'Cabina y Media. Grua HidroGrubert BL10C N\u00ba41878', veh_velocidad_max: '90', veh_tipo_id: 2, veh_tipo_nombre: 'Camioneta', chofer_nombre:'Sin asignar', chofer_apellido: '---' ,chofer_registro_categoria: '',chofer_registro_vencimiento: '2011-01-01',chofer_registro_flota_categoria: '', chofer_registro_flota_vencimiento: '2011-01-01', emp_nombre: 'Coop de Electricidad y Servicios Anexos', man_perfil_id: 3, man_perfil_nombre: 'Sin Definir !', grupo_nombre: 'Flota'}],
  [ { veh_id: 'AD156JD', veh_marca: 'TOYOTA', veh_nombre: '', veh_modelo: 'Hilux', veh_vtv_vencimiento: '', veh_cedula_verde_vencimiento: '', cli_id: 36, trk_id: 698, chofer_id: 8,veh_anio: '2018', veh_seguro_compania: '', veh_seguro_poliza: '',veh_seguro_vencimiento: '', veh_descripcion: '',veh_velocidad_max: '110', veh_tipo_id: 2, veh_tipo_nombre: 'Camioneta', chofer_nombre:'Sin asignar', chofer_apellido: '---', chofer_registro_categoria: '', chofer_registro_vencimiento: '2011-01-01',chofer_registro_flota_categoria: '',chofer_registro_flota_vencimiento: '2011-01-01', emp_nombre: 'Coop de Electricidad y Servicios Anexos', man_perfil_id: 3, man_perfil_nombre: 'Sin Definir !', grupo_nombre: 'Flota', odo_fecha: '2020-07-31', odo_valor: '36987'},
  { veh_id: 'AD113DJ1', veh_marca: 'IVECO1', veh_nombre: '', veh_modelo: 'Furgon1', veh_vtv_vencimiento: '', veh_cedula_verde_vencimiento: '', cli_id: 36, trk_id: 696, chofer_id: 8,veh_anio: '2018', veh_seguro_compania: '', veh_seguro_poliza: '',veh_seguro_vencimiento: '', veh_descripcion: '',veh_velocidad_max: '90', veh_tipo_id: 3, veh_tipo_nombre: 'Furgon', chofer_nombre:'Sin asignar', chofer_apellido: '---', chofer_registro_categoria: '', chofer_registro_vencimiento: '2011-01-01',chofer_registro_Flota_categoria: '',chofer_registro_flota_vencimiento: '2011-01-01', emp_nombre: 'Coop de Electricidad y Servicios Anexos', man_perfil_id: 3, man_perfil_nombre: 'Sin Definir !', grupo_nombre: 'Flota'},
  { veh_id: 'AD113DJ2', veh_marca: 'IVECO2', veh_nombre: '', veh_modelo: 'Furgon2', veh_vtv_vencimiento: '', veh_cedula_verde_vencimiento: '', cli_id: 36, trk_id: 696, chofer_id: 8,veh_anio: '2018', veh_seguro_compania: '', veh_seguro_poliza: '',veh_seguro_vencimiento: '', veh_descripcion: '',veh_velocidad_max: '90', veh_tipo_id: 3, veh_tipo_nombre: 'Furgon', chofer_nombre:'Sin asignar', chofer_apellido: '---', chofer_registro_categoria: '', chofer_registro_vencimiento: '2011-01-01',chofer_registro_Flota_categoria: '',chofer_registro_flota_vencimiento: '2011-01-01', emp_nombre: 'Coop de Electricidad y Servicios Anexos', man_perfil_id: 3, man_perfil_nombre: 'Sin Definir !', grupo_nombre: 'Flota'}],
  [{ veh_id: 'AD113DJ3', veh_marca: 'IVECO3', veh_nombre: '', veh_modelo: 'Furgon3', veh_vtv_vencimiento: '', veh_cedula_verde_vencimiento: '', cli_id: 36, trk_id: 696, chofer_id: 8,veh_anio: '2018', veh_seguro_compania: '', veh_seguro_poliza: '',veh_seguro_vencimiento: '', veh_descripcion: '',veh_velocidad_max: '90', veh_tipo_id: 3, veh_tipo_nombre: 'Furgon', chofer_nombre:'Sin asignar', chofer_apellido: '---', chofer_registro_categoria: '', chofer_registro_vencimiento: '2011-01-01',chofer_registro_Flota_categoria: '',chofer_registro_flota_vencimiento: '2011-01-01', emp_nombre: 'Coop de Electricidad y Servicios Anexos', man_perfil_id: 3, man_perfil_nombre: 'Sin Definir !', grupo_nombre: 'Flota'},
  { veh_id: 'EQN506', veh_marca: 'FORD', veh_nombre: '', veh_modelo: 'F4000', veh_vtv_vencimiento: '', veh_cedula_verde_vencimiento: '', cli_id: 36, trk_id: 720, chofer_id: 8,veh_anio: '2004', veh_seguro_compania: '', veh_seguro_poliza: '',veh_seguro_vencimiento: '', veh_descripcion: 'Hidroelevador Hidro Grubert Bl15CLA 2017', veh_velocidad_max: '90', veh_tipo_id: 5, veh_tipo_nombre: 'Hidro', chofer_nombre:'Sin asignar', chofer_apellido: '---',chofer_registro_categoria: '', chofer_registro_vencimiento: '2011-01-01', chofer_registro_flota_categoria: '', chofer_registro_flota_vencimiento: '2011-01-01', emp_nombre: 'Coop de Electricidad y Servicios Anexos', man_perfil_id: 3, man_perfil_nombre: 'Sin Definir !', grupo_nombre: 'Flota'},
  { veh_id: 'VHE182', veh_marca: 'FORD', veh_nombre: '', veh_modelo: 'F100', veh_vtv_vencimiento: '', veh_cedula_verde_vencimiento: '', cli_id: 36, trk_id: 712, chofer_id: 8,veh_anio: '1994', veh_seguro_compania: '', veh_seguro_poliza: '',veh_seguro_vencimiento: '', veh_descripcion: 'Cabina y Media. Grua HidroGrubert BL10C N\u00ba41878', veh_velocidad_max: '90', veh_tipo_id: 2, veh_tipo_nombre: 'Camioneta', chofer_nombre:'Sin asignar', chofer_apellido: '---' ,chofer_registro_categoria: '',chofer_registro_vencimiento: '2011-01-01',chofer_registro_flota_categoria: '', chofer_registro_flota_vencimiento: '2011-01-01', emp_nombre: 'Coop de Electricidad y Servicios Anexos', man_perfil_id: 3, man_perfil_nombre: 'Sin Definir !', grupo_nombre: 'Flota'}]
];

const options = [
  { value: 0, text: 'AE139KF'},
  { value: 1, text: 'EQN506'},
  { value: 2, text: 'VHE182'},
  { value: 3, text: 'AD156JD'},
  { value: 4, text: 'AD113DJ'}
];

export default { fields, items, options };
