<template>
  <div class="title"><h4> {{titleView}} </h4></div>
</template>

<script>
import moduleMenu from "./ModuleMenu";

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import { store } from "../../../../ingeo-mf-styleguide/src/store_";
/// #else
import { store } from "@ingeo-mf/styleguide";
/// #endif

export default {
  name: 'Config',
  mixins: [ ],
	components: {

  },
  data () {
    return {
      titleModule: "Ajuste"
      // titleSubModule: "",
    }
  },
  mounted () {
    this.setMenuData(moduleMenu.menuItems);
    this.setModuleName(moduleMenu.moduleName);
  },
  computed: {
    titleView() {
      return `${this.titleModule}`
    }
  },
  methods: {
    setMenuData: store.mutations.setMenuItems,
    setModuleName: store.mutations.setModuleName,       
  },
}
</script>
