const service = {
  TABLE: 'user',
  PRIMARY_KEY: 'id'
};

const servicePivot = {
  TABLE: 'grupousuario'
};

const titleModule = 'Ajuste';
const titleSubModule = 'Usuarios';

const names = {
  ID: 'id',
  USERID: 'usr_id',
  USERNAME: 'username',
  FIRSTNAME: 'usr_nombre',
  LASTNAME: 'usr_apellido',
  EMAIL: 'email',
  PASS: 'password',
  ACTIVATED: 'actived',
  BANNED: 'banned',
  BANREASON: 'ban_reason',
  CLIENT: 'cli_id',
  DATA: 'user_data',
  CREATED: 'created',
  MODIFIED: 'modified',
  PROFILE: 'usr_profile',
  PERMISSION: 'usr_permission',
  LASTIP: 'last_ip',
  ACTIVATED: 'activated',
  BANNED: 'banned',
  BANREASON: 'ban_reason',
  CHECK: 'check'
}

const resourceDisplayName = {
  [names.ID]: 'Id',
  [names.USERID]: 'Id',
  [names.USERNAME]: 'Usuario',
  [names.FIRSTNAME]: 'Nombre',
  [names.LASTNAME]: 'Apellido',
  [names.EMAIL]: 'Email',
  [names.PASS]: 'Password',
  [names.CLIENT]: 'Cliente',
  [names.CREATED]: 'Creado',
  [names.MODIFIED]: 'Modificado',
  [names.PROFILE]: 'Perfil',
  [names.PERMISSION]: 'Permisos',
  [names.LASTIP]: 'Ult. IP',
  [names.ACTIVATED]: 'Activado',
  [names.BANNED]: 'Suspendido',
  [names.BANREASON]: 'Motivo',
  [names.CHECK]: 'Marcado',
}

const fields = [
  // {
  //   key: 'index',
  //   label: '#'
  // },
  {
    key: names.USERNAME,
    label: resourceDisplayName[names.USERNAME],
    sortable: true
  },
  {
    key: names.LASTNAME,
    label: resourceDisplayName[names.LASTNAME],
    sortable: true
  },
  {
    key: names.FIRSTNAME,
    label: resourceDisplayName[names.FIRSTNAME],
    sortable: true
  },
  {
    key: names.EMAIL,
    label: resourceDisplayName[names.EMAIL],
    sortable: true
  },
  {
    key: names.PROFILE,
    label: resourceDisplayName[names.PROFILE],
    sortable: true
  },
  {
    key: 'show_details',
    label: '',
    thStyle: {width: '30px !important'}
  }
];

const fieldsPivot = [
  {
    key: names.USERNAME,
    label: resourceDisplayName[names.USERNAME],
    sortable: true
  },
  {
    key: names.LASTNAME,
    label: resourceDisplayName[names.LASTNAME],
    sortable: true
  },
  {
    key: names.FIRSTNAME,
    label: resourceDisplayName[names.FIRSTNAME],
    sortable: true
  },
  {
    key: names.PROFILE,
    label: resourceDisplayName[names.PROFILE],
    sortable: true
  },
  {
    key: names.CHECK,
    label: resourceDisplayName[names.CHECK],
    sortable: true,
    thStyle: {width: '30px !important'}
  }
];

export default {
  service,
  servicePivot,
  titleModule,
  titleSubModule,
  names,
  fields,
  fieldsPivot,
  methods: {
    /**
     * Resource Normalized name
     * @param regKey {String}
     * @returns {String}
     */
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    }
  }
}
