const service = {
  TABLE: 'tracker',
  PRIMARY_KEY: 'trk_id'
};
const titleModule = 'Ajuste';
const titleSubModule = 'Trackers';
const tracker = {
  ID: 'trk_id',
  // TRACKER_NUMBER: 'trk_id', //'chip_numero',
  TRACKER_HW: 'hw_id',
  TRACKER_SERIE: 'trk_serial',
  TRACKER_CAR: 'trk_car',
  STATE: 'estado_id',
  TRACKER_CHIP_NUMBER1: 'chip_numero',
  TRACKER_CHIP_NUMBER2: 'chip_numero_2',
  TRACKER_OBSERVATIONS: 'trk_observaciones',
  TRACKER_CLIENT: 'cli_id',
  TRACKER_NAME_CLIENT: 'text',
  TRACKER_TARGET_PORT: 'trk_target_port'

}

const resourceDisplayName = {
  [tracker.ID]: 'Id',
  [tracker.TRACKER_NUMBER]: 'Tracker ID',
  [tracker.TRACKER_HW]: 'Modelo',
  [tracker.TRACKER_SERIE]: 'S/N',
  [tracker.TRACKER_CAR]: 'Vehículo',
  [tracker.STATE]: 'Estado',
  [tracker.TRACKER_CHIP_NUMBER1]: 'Chip 1',
  [tracker.TRACKER_CHIP_NUMBER2]: 'Chip 2',
  [tracker.TRACKER_OBSERVATIONS]: 'Observaciones',
  [tracker.TRACKER_CLIENT]: 'Id Cliente',
  [tracker.TRACKER_TARGET_PORT]: 'Puerto',
  [tracker.TRACKER_NAME_CLIENT]: 'Cliente',
}

const fields = [
  // {
  //   key: 'index',
  //   label: '#'
  // },
  {
    key: tracker.ID,
    label: resourceDisplayName[tracker.TRACKER_NUMBER],
    sortable: true
  },
  {
    key: tracker.TRACKER_SERIE,
    label: resourceDisplayName[tracker.TRACKER_SERIE],
    sortable: true
  },
  {
    key: tracker.TRACKER_HW,
    label: resourceDisplayName[tracker.TRACKER_HW],
    sortable: true
  },
  {
    key: tracker.STATE,
    label: resourceDisplayName[tracker.STATE],
    sortable: true
  },
  {
    key: tracker.TRACKER_CAR,
    label: resourceDisplayName[tracker.TRACKER_CAR],
    sortable: true
  },
  {
    key: tracker.TRACKER_CHIP_NUMBER1,
    label: resourceDisplayName[tracker.TRACKER_CHIP_NUMBER1],
    sortable: true
  },
  {
    key: tracker.TRACKER_CHIP_NUMBER2,
    label: resourceDisplayName[tracker.TRACKER_CHIP_NUMBER2],
    sortable: true
  },
  {
    key: [tracker.TRACKER_CLIENT],
    label: resourceDisplayName[tracker.TRACKER_CLIENT],
    sortable: true
  },
  {
    key: [tracker.TRACKER_OBSERVATIONS],
    label: resourceDisplayName[tracker.TRACKER_OBSERVATIONS],
    sortable: true
  },
  {
    key: [tracker.TRACKER_TARGET_PORT],
    label: resourceDisplayName[tracker.TRACKER_TARGET_PORT],
    sortable: true
  },
 
  {
    key: 'show_details',
    label: '',
    thStyle: {width: '30px !important'}
  }
];

const estado = [
  { value: 'ACK', text: 'ACK' },
  { value: 'ACT', text: 'ACT' },
  { value: 'BAD', text: 'BAD' },
  { value: 'BLK', text: 'BLK' },
  { value: 'CAN', text: 'CAN'},
  { value: 'DIS', text: 'DIS'},
  { value: 'ERR', text: 'ERR'},
  { value: 'NEW', text: 'NEW'},
  { value: 'PND', text: 'PND'},
  { value: 'RDY', text: 'RDY'},
  { value: 'SUS', text: 'SUS'},
  { value: 'TST', text: 'TST'},
  { value: 'WAI', text: 'WAI'},
];

const cliente = [
  { value: '1', text: 'Ingenea' },
  { value: '2', text: 'CSDNET' },
  { value: '3', text: 'GCBA' },
  { value: '4', text: 'SOLFLIX' },
  { value: '5', text: 'ROSSINI'},
  { value: '6', text: 'CHOPLIN'},
  { value: '7', text: 'VALTELLINA'},
  { value: '8', text: 'Ingeo'},
  { value: '9', text: 'Mun. Arenales'},
  { value: '10', text: 'ASSA'},
  { value: '11', text: 'Intecndt'},
  { value: '12', text: 'GMSUR'},
  { value: '13', text: 'Grupo Cammarata'},
]

export default {
  estado,
  cliente,
  service,
  titleModule,
  titleSubModule,
  tracker,
  fields,
  methods: {
    /**
     * Resource Normalized name
     * @param regKey {String}
     * @returns {String}
     */
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    }
  }
}
