<template>
  <crud :service="service"  :items="items" :fields="fields" :fileName="fileName" :isBusy="isBusy">

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>
    
    <template v-slot:ItemFormLayout="{itemForm, isFormDisable}">

      <b-card bg-variant="light" >
        <!-- Fila 1 -->
        <b-form-row>
          <b-col sm="6" v-show="viewId">
            <b-form-group label-for="input-1" id="input-1">
              <label>{{ resourceDisplayName(names.ID) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-1" type="text"
                v-model="itemForm[names.ID]"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label-for="input-2" id="input-2"
              :invalid-feedback="invalidFeedbackName(itemForm[names.NAME])"
              :state="stateName(itemForm[names.NAME])"
            >
              <label>{{ resourceDisplayName(names.NAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-3" required type="text"
                v-model="itemForm[names.NAME]" :state="stateName(itemForm[names.NAME])"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="6" >
            <b-form-group label-for="input-4" id="input-4">
              <label>{{ resourceDisplayName(names.DESCRIPTION) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-4" type="text"
                v-model="itemForm[names.DESCRIPTION]"
              ></b-form-input>
            </b-form-group>
          </b-col>

           <b-col sm="6" v-show="viewId">
            <b-form-group label-for="input-5">
              <label>{{ resourceDisplayName(names.CLIENT) }}</label>
               <b-form-input :disabled="isFormDisable" id="input-5" type="text"
                  v-model="client_id"
              ></b-form-input>
            </b-form-group>
          </b-col>
            
          <b-col sm="6">
            <b-form-group label-for="input-6" id="input-6">
              <label>{{ resourceDisplayName(names.COLOR) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-6" type="color" class="form-control" 
                :v-model="itemForm[names.COLOR]" :value="itemForm[names.COLOR]" :formatter="getColor"> 
          </b-form-input>
              <!-- :formatter="getColor" -->
            </b-form-group>
          </b-col>
          <!-- <b-col sm="6">
            <b-form-group label-for="input-3" id="input-3">
              <label>{{ resourceDisplayName(names.COLOR) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-3" type="color" class="form-control" 
                :v-model="`#${itemForm[names.COLOR]}`" :value="getColor(itemForm[names.COLOR])" >
              </b-form-input>
            </b-form-group>
          </b-col> -->

        </b-form-row>
      </b-card>

    </template>

    <!-- <template v-slot:ItemTableLayout="{item}">
      <b-card-group>
        <b-card  class="font-weight-bold" style="max-width: 20rem; border: 0px solid;">
          <b class="font-weight-bold"> {{ resourceDisplayName(names.CREATED) }} </b>
          <b class="font-weight-normal"> {{ item[names.CREATED]}} </b>
        </b-card>
        <b-card style="max-width: 20rem; border: 0px solid;">
          <b class="font-weight-bold"> {{ resourceDisplayName(names.CLIENT) }} </b>
          <b class="font-weight-normal"> {{ item[names.CLIENT]}} </b>
        </b-card>
      </b-card-group>
    </template> -->

  </crud>
</template>

<script>
import Crud from '@/components/Crud/Index';
import resources from '@/mixins/resourcesGroup'
import customCell from '@/mixins/customCell'
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import grupoService from "../../../../ingeo-mf-styleguide/src/services/grupo";
import authService from "../../../../ingeo-mf-styleguide/src/services/auth";

/// #else
import { grupoService } from "@ingeo-mf/styleguide";
import { authService } from "@ingeo-mf/styleguide";

/// #endif

export default {
  name: 'ConfigGrupos',
  mixins: [resources, customCell],
	components: {
    Crud
  },
  mixins: [resources],
  data () {
    return {
      titleModule: resources.titleModule,
      titleSubModule: resources.titleSubModule,
      isViewForm: false,
      indexSelected: -1,

      fields: resources.fields,
      names: resources.names,
      service: resources.service,
      items: [],
      cliente: resources.cliente,
      service: resources.service,
      isBusy: false,
      viewId: false,
      viewClient: false,
      client_id: []
    }
  },
  computed: {
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`
    },
    fileName() {
      return `${this.titleModule}_${this.titleSubModule}`
    }
  },
  mounted() {
      grupoService.index().then((res) => {
        this.isBusy = true
        res.data.forEach(element => {
          this.items.push(element);
       });
      
      authService.userInfo().then((res) => {
        console.log("res", res)
        this.client_id = res.data.cli_id
        this.isBusy = false
      });
      console.log("mounted ->  this.items",  this.items)
         
      });
  },
  methods: {
    stateName (value) {
      if(!value) {
        return false
      } else {
        return value.length >= 4 ? true : false
      }
    },
   
    getColor (value) {
     
      if(!value) {
        return ``
      } else {
        
        return value.substring(1,7) //`${value.substr(1, 6)}` 
      }
    },
    invalidFeedbackName(value) {
      if(!value) {
        return 'Ingrese Grupo'
      }
      if (value.length >= 4) {
        return ''
      } else if (value.length > 0) {
        return 'Ingrese al menos 4 caracteres'
      } else {
        return 'Ingrese Grupo'
      }
    }
  }
}
</script>

