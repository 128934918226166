const service = {
  TABLE: 'grupo',
  PRIMARY_KEY: 'grupo_id'
};
const titleModule = 'Ajuste';
const titleSubModule = 'Grupos';

const names = {
  ID: 'grupo_id',
  NAME: 'grupo_nombre',
  DESCRIPTION: 'grupo_descripcion',
  COLOR: 'grupo_color',
  CREATED: 'grupo_fecha_creacion',
  CLIENT: 'cli_id',
  CHECK: 'grupo_checked',
  ICON: 'grupo_icon'
}

const resourceDisplayName = {
  [names.ID]: 'Id',
  [names.NAME]: 'Nombre',
  [names.DESCRIPTION]: 'Descripción',
  [names.COLOR]: 'Color',
  [names.CREATED]: 'Creado',
  [names.CLIENT]: 'Cliente',
  [names.CHECK]: 'Check',
  [names.ICON]: 'Icon'
}

const fields = [
  // {
  //   key: 'index',
  //   label: '#'
  // },
  {
    key: names.NAME,
    label: resourceDisplayName[names.NAME],
    sortable: true,
  },
  {
    key: names.DESCRIPTION,
    label: resourceDisplayName[names.DESCRIPTION],
    sortable: true,
  },
  {
    key: names.COLOR,
    label: resourceDisplayName[names.COLOR],
    sortable: true,
    customCell: true,
    //formatter: 'customGroupColor'         // OP1 call function por mixims
    formatter: (value, key, item) => {      // OP2 valido solo para recalcular/procesar "value"
      value = value.charAt(0) === '#' ? value : `#${value}` 
      let cell = `<div style="background-color: ${value}; font-color:${value}; border-radius: 6px;">
                    &nbsp;
                  </div>`
      return cell
    }
  },
  // {
  //   key: 'show_details',
  //   label: '',
  //   thStyle: {width: '30px !important'}
  // }
]

export default {
  service,
  titleModule,
  titleSubModule,
  names,
  fields,
  methods: {
    /**
     * Resource Normalized name
     * @param regKey {String}
     * @returns {String}
     */
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    }
  }
}
